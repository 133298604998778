"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpiredCredentialsError = void 0;
const BaseError_1 = require("./BaseError");
const DefaultErrorMessage = 'Credentials are expired';
class ExpiredCredentialsError extends BaseError_1.BaseError {
    constructor(message = DefaultErrorMessage) {
        super(message);
        this.statusCode = 401;
    }
}
exports.ExpiredCredentialsError = ExpiredCredentialsError;
