import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { BaseError, Conditional, CreateSuperuser, Superuser, ValidateCreateSuperuser } from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext, SuperuserApiState } from "../contexts/AuthContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { SuperuserApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";

function *CreateSuperuserValidator(input: CreateSuperuser) {
  yield *ValidateCreateSuperuser(input);
}

export function SuperuserCreatePanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);
  const navigate = useNavigate();

  const resolver = useValidatorResolver(CreateSuperuserValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<CreateSuperuser>({
    resolver,
  });

  const onSubmit: SubmitHandler<CreateSuperuser> = async (createSuperuser) => {
    setIsLoading(true);

    try {
      const api = (apiState! as SuperuserApiState).superuserApi;
      const resultSuperuser: Superuser = await api.createSuperuser(createSuperuser);

      setIsLoading(false);
      toast('Success');
      navigate('/admin');
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };
  
  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Create Admin</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/admin'>&lt; Admins</ColoredLink>
    </AuthenticatedSubheader>
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <ValidatedInput type='email' fieldName='email' register={register} errors={errors}/>
      <SpinnerButton type='submit' isLoading={isLoading}>
        Create
      </SpinnerButton>
      <Conditional on={apiError}>
        <ErrorLabel>
          {apiError?.message}
        </ErrorLabel>
      </Conditional>
    </FormContainer>
  </>;
}