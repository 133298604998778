import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BaseError, Conditional, Superuser, IfElse, DeleteSuperuser, UpdateSuperuser, ValidateUpdateSuperuser, } from "@sfiaf/common";
import { ErrorLabel } from "../components/ErrorLabel";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext, SuperuserApiState } from "../contexts/AuthContext";
import { SuperuserApi, SuperuserUserApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { FormContainer } from "../components/FormContainer";
import { DeleteResource } from "./DeleteResource";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";

function *UpdateSuperuserValidator(input: UpdateSuperuser) {
  yield *ValidateUpdateSuperuser(input);
}


interface UpdateSuperuserProps {
  superuser: Superuser;
}

function UpdateSuperuserForm({superuser}: UpdateSuperuserProps) {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals } = useContextOrThrow(FestivalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(UpdateSuperuserValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<UpdateSuperuser>({
    resolver,
    defaultValues: superuser,
  });

  const onSubmit: SubmitHandler<UpdateSuperuser> = async (updateSuperuser) => {
    setIsLoading(true);

    try {
      const api = (apiState! as SuperuserApiState).superuserApi;
      await api.updateSuperuser(updateSuperuser);

      setIsLoading(false);
      toast(`Success`);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <ValidatedInput type='email' fieldName='email' register={register} errors={errors}/>
    <SpinnerButton type='submit' isLoading={isLoading}>
      Update
    </SpinnerButton>

    <Conditional on={apiError}>
      <ErrorLabel>
        {apiError?.message}
      </ErrorLabel>
    </Conditional>
  </FormContainer>;
}



export function SuperuserDetailsPanel() {
  const { authState, apiState } = useContextOrThrow(AuthContext);

  const currentSuperuserId: number = authState!.currentUser.id;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [superuser, setSuperuser] = useState<Superuser | null>(null);

  const superuserIdString: string | undefined = useParams()['*'];
  const superuserId: number = Number(superuserIdString);

  useAsyncMountEffect(async () => {
    const api: SuperuserApi = (apiState! as SuperuserApiState).superuserApi;

    try {
      const superuser: Superuser = await api.getSuperuser(superuserId);

      setSuperuser(superuser);
      setIsLoading(false);
    }
    catch (e) {
      toast(`Failed to fetch admins. Please try again later. (Reason: ${e}.)`);
    }

    setIsLoading(false);
  });

  const onDeletion = async (superuser: Superuser) => {
    const api = (apiState! as SuperuserApiState).superuserApi;
    const deleteSuperuser: DeleteSuperuser = {
      id: superuser.id,
    };

    await api.deleteSuperuser(deleteSuperuser);
  };

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Admin Details: {superuser?.email}</AuthenticatedHeaderTitle>
      </AuthenticatedHeader>
      <AuthenticatedSubheader>
        <ColoredLink to='/admin'>&lt; Admins</ColoredLink>
        <Conditional on={currentSuperuserId !== superuserId}>
          <DeleteResource resource={superuser!} onDeletion={onDeletion} redirect='/admin'/>
        </Conditional>
      </AuthenticatedSubheader>
      <IfElse on={superuser}>
        <>
          <UpdateSuperuserForm superuser={superuser!}/>
        </>
        <>
          <FullHeightCenteredContentContainer>
            <ErrorLabel>Record does not exist</ErrorLabel>
          </FullHeightCenteredContentContainer>
        </>
      </IfElse>
    </>
  </IfElse>
  
}