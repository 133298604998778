"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeletableImageUpload = exports.ExtractDeletableImageUpload = exports.DeletableImageUploadAction = exports.ValidatePartialImageUpload = exports.ValidateImageUpload = exports.ExtractPartialImageUpload = exports.ExtractImageUpload = void 0;
const error_1 = require("../error");
const validator_1 = require("../validator");
function ExtractImageUpload(input) {
    const { imageData, imageContentType, } = input;
    return {
        imageData,
        imageContentType,
    };
}
exports.ExtractImageUpload = ExtractImageUpload;
function ExtractPartialImageUpload(input) {
    const { imageData, imageContentType, } = input;
    return {
        imageData,
        imageContentType,
    };
}
exports.ExtractPartialImageUpload = ExtractPartialImageUpload;
function* ValidateImageUpload(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'imageData');
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'imageContentType');
    const { imageData, imageContentType } = input;
    return {
        imageData,
        imageContentType,
    };
}
exports.ValidateImageUpload = ValidateImageUpload;
function* ValidatePartialImageUpload(input) {
    const { imageData, imageContentType, } = input;
    if (imageData != null || imageContentType != null) {
        if (imageData == null || imageContentType == null) {
            yield new error_1.ValidationError(`imageData and imageContentType should either both be null or non-null.`, 'imageData');
        }
    }
    return {
        imageData,
        imageContentType,
    };
}
exports.ValidatePartialImageUpload = ValidatePartialImageUpload;
var DeletableImageUploadAction;
(function (DeletableImageUploadAction) {
    DeletableImageUploadAction["Upload"] = "Upload";
    DeletableImageUploadAction["Noop"] = "Noop";
    DeletableImageUploadAction["Delete"] = "Delete";
})(DeletableImageUploadAction || (exports.DeletableImageUploadAction = DeletableImageUploadAction = {}));
;
function* ExtractDeletableImageUpload(input) {
    const { action, imageData, imageContentType } = input;
    return {
        action,
        imageData,
        imageContentType,
    };
}
exports.ExtractDeletableImageUpload = ExtractDeletableImageUpload;
function* ValidateDeletableImageUpload(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'action');
    yield* ValidatePartialImageUpload(input);
    const { action, imageData, imageContentType, } = input;
    switch (action) {
        case DeletableImageUploadAction.Upload:
            if (imageData == null || imageContentType == null) {
                yield new error_1.ValidationError(`For Upload action for image, both imageData and imageContentType must be non-null`, 'action');
            }
            break;
        case DeletableImageUploadAction.Noop:
            if (imageData != null || imageContentType != null) {
                yield new error_1.ValidationError(`For Noop action for image, both imageData and imageContentType must be null`, 'action');
            }
            break;
        case DeletableImageUploadAction.Delete:
            if (imageData != null || imageContentType != null) {
                yield new error_1.ValidationError(`For Delete action for image, both imageData and imageContentType must be null`, 'action');
            }
            break;
        default:
            yield new error_1.ValidationError(`Unrecognized action for deletable image upload: ${action}`, 'action');
    }
    return {
        action,
        imageData,
        imageContentType,
    };
}
exports.ValidateDeletableImageUpload = ValidateDeletableImageUpload;
