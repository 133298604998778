import { LoginScreen } from './screens/LoginScreen';

import { AuthContext, AuthContextState, } from './contexts/AuthContext';
import { AuthenticatedPanel, SuperuserAuthenticatedPanel } from './panels/AuthenticatedPanel';
import { AuthenticatedScreen } from './screens/AuthenticatedScreen';

import {
  UserType
} from '@sfiaf/common';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useContext } from 'react';
import { MagicLinkScreen } from './screens/MagicLinkScreen';

export const RouteWrapper = () => {
  const authContextState: AuthContextState | null = useContext(AuthContext);

  let router: React.JSX.Element;

  if (authContextState == null || authContextState.authState == null) {
    router = 
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen/>}/>
          <Route path="/magic-link/superuser" element={<MagicLinkScreen userType={UserType.Superuser}/>}/>
          <Route path="/magic-link/user" element={<MagicLinkScreen userType={UserType.User}/>}/>
          <Route path="*" element={<Navigate to="/login"/>}/>
        </Routes>
      </Router>;
  }
  else if (authContextState.authState.userType === UserType.Superuser) {
    router =
      <Router>
        <Routes>
          <Route path="/" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.FestivalList} />} />
          <Route path="/festival" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.FestivalList} />} />
          <Route path="/festival/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.FestivalCreate} />} />
          <Route path="/festival/*" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.FestivalDetails} />} />
          <Route path="/discipline" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.DisciplineList} />} />
          <Route path="/discipline/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.DisciplineCreate} />} />
          <Route path="/discipline/*" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.DisciplineDetails} />} />
          <Route path="/show" element={<AuthenticatedScreen panel={AuthenticatedPanel.ShowList} />} />
          <Route path="/show/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.ShowCreate} />} />
          <Route path="/show/*" element={<AuthenticatedScreen panel={AuthenticatedPanel.ShowDetails} />} />
          <Route path="/location" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.LocationList} />} />
          <Route path="/location/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.LocationCreate} />} />
          <Route path="/location/*" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.LocationDetails} />} />
          <Route path="/admin" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.SuperuserList} />} />
          <Route path="/admin/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.SuperuserCreate} />} />
          <Route path="/admin/*" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.SuperuserDetails} />} />
          <Route path="/artist" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.UserList} />} />
          <Route path="/artist/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.UserCreate} />} />
          <Route path="/artist/*" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.UserDetails} />} />
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
      </Router>;
  }
  else {  // regular user/artist
    router =
      <Router>
        <Routes>
          <Route path="/" element={<AuthenticatedScreen panel={AuthenticatedPanel.ShowList} />} />
          <Route path="/show" element={<AuthenticatedScreen panel={AuthenticatedPanel.ShowList} />} />
          <Route path="/show/create" element={<AuthenticatedScreen panel={SuperuserAuthenticatedPanel.ShowCreate} />} />
          <Route path="/show/*" element={<AuthenticatedScreen panel={AuthenticatedPanel.ShowDetails} />} />
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
      </Router>;
  }

  return router;
}