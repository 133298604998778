"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildApiError = exports.ApiError = void 0;
const BaseError_1 = require("./BaseError");
class ApiError extends BaseError_1.BaseError {
    constructor(statusCode, name, message) {
        super(message);
        this.statusCode = statusCode;
        this.name = name;
    }
}
exports.ApiError = ApiError;
function BuildApiError(e) {
    let statusCode;
    let name;
    let message;
    if ('statusCode' in e) {
        statusCode = e.statusCode;
    }
    else {
        statusCode = 500;
    }
    if ('name' in e) {
        name = e.name;
    }
    else {
        name = 'UnrecognizedApiError';
    }
    if ('message' in e) {
        message = e.message;
    }
    else {
        message = `Error details: ${e}`;
    }
    const apiError = new ApiError(statusCode, name, message);
    return apiError;
}
exports.BuildApiError = BuildApiError;
