import {
  BaseError,
  IfElse,
  Sleep,
  Superuser,
  UserOAuthAccessToken,
  UserType,
  ValidationError
} from "@sfiaf/common";
import {
  SuperuserLoginApi,
  UserLoginApi
} from '@sfiaf/api';

import { Environment } from "../environment/Environment";
import { useAsyncMountEffect, useMountEffect } from "../utils/useMountEffect";

import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { ErrorLabel } from "../components/ErrorLabel";
import { CenteredDisplayLabel } from "../components/CenteredDisplayLabel";
import { ColoredLink } from "../components/ColoredLink";

import styled from "styled-components";
import { TailSpin } from 'react-loader-spinner';
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext, AuthContextState, AuthState, BuildSuperuserApiState } from "../contexts/AuthContext";
import { Theme } from "../theme/Theme";

export interface MagicLinkScreenProps {
  userType: UserType;
}

const VerifyingLabel = styled(CenteredDisplayLabel)`
  margin-top: 10px;
`;

export function MagicLinkScreen({
  userType,
}: MagicLinkScreenProps) {
  const { setAuthState } = useContextOrThrow(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const token: string | null = searchParams.get('token');

  const [apiError, setApiError] = useState<BaseError | null>(null);

  useAsyncMountEffect(async () => {
    if (token == null) {
      return;
    }

    try {
      if (userType === UserType.Superuser) {
        const api = new SuperuserLoginApi(Environment.beBaseUrl);
        const {
          superuser,
          accessToken
        } = await api.login(token);

        const authState: AuthState = {
          userType: UserType.Superuser,
          currentUser: superuser,
          accessToken,
        };

        setAuthState(authState);
      }
      else {
        const api = new UserLoginApi(Environment.beBaseUrl);
        const {
          user,
          accessToken
        } = await api.login(token);

        const authState: AuthState = {
          userType: UserType.User,
          currentUser: user,
          accessToken,
        };

        setAuthState(authState);
      }
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
    }
  });

  let errorMessage: string | undefined;
  if (token == null || token.length === 0) {
    errorMessage = 'Token must be provided';
  }
  else if (apiError != null) {
    errorMessage = apiError.message;
  }

  return <FullHeightCenteredContentContainer>
    <h1>Magic Link</h1>
    <IfElse on={errorMessage}>
      <>
        <ErrorLabel>{errorMessage}</ErrorLabel>
        <ColoredLink to='/login'>Go to log-in screen</ColoredLink>
      </>
      <>
        <TailSpin
        visible={true}
        height="20"
        width="20"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
        <VerifyingLabel>Verifying...</VerifyingLabel>
      </>
    </IfElse>
  </FullHeightCenteredContentContainer>;
}