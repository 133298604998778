"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRequiredEnvVarOrExit = exports.GetOptionalEnvVar = void 0;
function GetOptionalEnvVar(identifier) {
    const value = process.env[identifier];
    return value;
}
exports.GetOptionalEnvVar = GetOptionalEnvVar;
function GetRequiredEnvVarOrExit(identifier) {
    const value = process.env[identifier];
    if (value == null || value.length === 0) {
        console.error(`FATAL: Required env var ${identifier} not defined.`);
        process.exit(-1);
    }
    return value;
}
exports.GetRequiredEnvVarOrExit = GetRequiredEnvVarOrExit;
