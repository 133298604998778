import styled from 'styled-components';
import { UseFormRegister, FieldErrors, FieldValues, Path, } from "react-hook-form";
import { HTMLAttributes, InputHTMLAttributes, PropsWithChildren, SelectHTMLAttributes, TextareaHTMLAttributes, useState } from 'react';
import * as _ from "lodash";
import { Conditional, IfElse, ValidationError } from '@sfiaf/common';
import { ErrorLabel } from './ErrorLabel';

export const ValidatedFieldContainer = styled(ValidatedFieldContainerInternal)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TitleLabel = styled.label`
  display: block;
`;



export interface BaseValidatedFieldProps<T extends FieldValues> {
  fieldName: Path<T>;
  title?: string;
  optional?: boolean;  // if false, adds an asterisk `*` at the end of the final title. default is false.
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
}

type ValidatedFieldContainerProps<T extends FieldValues> = PropsWithChildren<Omit<BaseValidatedFieldProps<T>, 'register'>>;

function ValidatedFieldContainerInternal<T extends FieldValues>({
  fieldName,
  title,
  optional = false,
  errors,
  children,
}: ValidatedFieldContainerProps<T>) {
  const error: ValidationError | undefined = errors[fieldName] as ValidationError;

  let finalTitle: string;
  if (title != null) {
    finalTitle = title;
  }
  else {
    finalTitle = _.startCase(fieldName);
  }

  if (optional === false) {
    finalTitle = `${finalTitle}*`;
  }

  return <>
    <TitleLabel>{finalTitle}</TitleLabel>
    {children}
    <ErrorLabel>
      <Conditional on={error}>{error?.message}</Conditional>
    </ErrorLabel>
  </>;
}



export interface ValidatedInputProps<T extends FieldValues> extends
  InputHTMLAttributes<HTMLInputElement>,
  BaseValidatedFieldProps<T> {
  
}

export function ValidatedInput<T extends FieldValues>({
  fieldName,
  title,
  optional = false,
  register,
  errors,
  ...rest
}: ValidatedInputProps<T>) {
  const error: ValidationError | undefined = errors[fieldName] as ValidationError;

  const registerOptions: {[key: string]: any} = {};
  if (rest.type === 'number') {
    registerOptions['valueAsNumber'] = true;
  }

  return <ValidatedFieldContainer fieldName={fieldName} optional={optional} title={title} errors={errors}>
    <input {...register(fieldName, registerOptions)} {...rest} />
  </ValidatedFieldContainer>;
}

// export type ValidatedOptionType = Omit<JSX.Element, 'type'> & {type: 'option'};
// children: Array<ValidatedOptionType>; // force the children to always be `option` elements

export interface ValidatedSelectProps<T extends FieldValues> extends
  SelectHTMLAttributes<HTMLSelectElement>,
  BaseValidatedFieldProps<T>,
  PropsWithChildren {
  
}

export function ValidatedSelect<T extends FieldValues>({
  fieldName,
  title,
  optional = false,
  register,
  children,
  errors,
  ...rest
}: ValidatedSelectProps<T>) {
  return <ValidatedFieldContainer fieldName={fieldName} optional={optional} title={title} errors={errors}>
    <select {...register(fieldName)} {...rest}>
      {children}
    </select>
  </ValidatedFieldContainer>;
}


export interface ValidatedTextAreaProps<T extends FieldValues> extends
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  BaseValidatedFieldProps<T>,
  PropsWithChildren {
  
}

export function ValidatedTextArea<T extends FieldValues>({
  fieldName,
  title,
  optional = false,
  register,
  children,
  errors,
  ...rest
}: ValidatedTextAreaProps<T>) {
  return <ValidatedFieldContainer fieldName={fieldName} optional={optional} title={title} errors={errors}>
    <textarea {...register(fieldName)} {...rest}>
      {children}
    </textarea>
  </ValidatedFieldContainer>;
}