"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateRequiredProperty = void 0;
const error_1 = require("../error");
// Extract is required to limit the keys only to string, instead of the allowable number/symbol for a generic object.
//  Once the `json` type is introduced, this can probably be removed.
function* ValidateRequiredProperty(input, propName) {
    if (input == null) {
        yield new error_1.ValidationError(`The input must not be null when checking for required property`, propName);
        return;
    }
    if (!(propName in input)) {
        // toString is required 
        yield new error_1.ValidationError(`${String(propName)} is required`, propName);
    }
}
exports.ValidateRequiredProperty = ValidateRequiredProperty;
