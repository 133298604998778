"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanModel = void 0;
const ObjectKeyStripper_1 = require("../util/ObjectKeyStripper");
const ObjectStringValueTrimmer_1 = require("../util/ObjectStringValueTrimmer");
// IMPORTANT NOTE: this may return objects which break the expected types.
//  e.g. if we have a prop like `propName: string;`, and then the input is the empty string,
//  i.e. `propName: ''`, then the final result will *omit the key-value pair entirely*.
// TODO: use extends Json eventually
function CleanModel(input) {
    let result = input;
    result = (0, ObjectStringValueTrimmer_1.TrimObjectStringValues)(result);
    result = (0, ObjectKeyStripper_1.StripObjectKeysByValue)(result, NaN);
    result = (0, ObjectKeyStripper_1.StripObjectKeysByValue)(result, +Infinity);
    result = (0, ObjectKeyStripper_1.StripObjectKeysByValue)(result, -Infinity);
    result = (0, ObjectKeyStripper_1.StripObjectKeysByValue)(result, "");
    return result;
}
exports.CleanModel = CleanModel;
