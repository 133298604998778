import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { BaseError, Conditional, CreateDiscipline, Discipline, Festival, ValidateCreateDiscipline } from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext } from "../contexts/AuthContext";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { SuperuserDisciplineApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";
import { toBase64 } from "../utils/toBase64";
import Select from 'react-select';
import styled from "styled-components";
import { CenteredContentContainer } from "../components/CenteredContentContainer";

const Image = styled.img`
  object-fit: cover;
  aspect-ratio: 3/2;
  max-width: 600px;
`;

const ImageSection = styled(CenteredContentContainer)`
  margin-top: 20px;
  margin-bottom: 20px;
`

function CreateDisciplineValidatorWrapper(year: number, imageFile: File | null) {
  async function *CreateDisciplineValidator(input: CreateDiscipline) {
    if (imageFile != null) {
      input.imageContentType = imageFile.type;
      const imageBase64Full: string = (await toBase64(imageFile)) as string;
      const imageBase64: string = (imageBase64Full as string).split('base64,')[1];
      input.imageData = imageBase64;
    }

    input.festivalYear = year;
    yield *ValidateCreateDiscipline(input);
  }

  return CreateDisciplineValidator;
}


export function DisciplineCreatePanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals, disciplines, setDisciplines, disciplinesByYear, setDisciplinesByYear } = useContextOrThrow(FestivalContext);

  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(0);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const navigate = useNavigate();

  const festival: Festival = festivals[selectedYearIndex];

  const resolver = useValidatorResolver(CreateDisciplineValidatorWrapper(festival.year, imageFile));
  const { register, handleSubmit, formState: { errors } } = useForm<CreateDiscipline>({
    resolver,
  });

  const onSubmit: SubmitHandler<CreateDiscipline> = async (createDiscipline) => {
    setIsLoading(true);

    createDiscipline.festivalYear = festival.year;

    try {
      if (imageFile != null) {
        createDiscipline.imageContentType = imageFile.type;
        const imageBase64Full: string = (await toBase64(imageFile)) as string;
        const imageBase64: string = (imageBase64Full as string).split('base64,')[1];
        createDiscipline.imageData = imageBase64;
      }

      const api = apiState!.disciplineApi as SuperuserDisciplineApi;
      const resultDiscipline: Discipline = await api.createDiscipline(createDiscipline);

      disciplines.set(resultDiscipline.id, resultDiscipline);
      setDisciplines(disciplines);

      let yearDisciplines: Array<Discipline> | undefined = disciplinesByYear.get(resultDiscipline.festivalYear)!;
      yearDisciplines.push(resultDiscipline);
      disciplinesByYear.set(resultDiscipline.festivalYear, yearDisciplines);
      setDisciplinesByYear(disciplinesByYear);

      setIsLoading(false);
      toast('Success');
      navigate('/discipline');
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  const selectOptions = festivals.map((festival, index) => ({value: index, label: `${festival.year}`}));
  if (selectOptions.length === 0) {
    selectOptions.push({value: 0, label: `N/A`});
  }

  let imageError: string | undefined;
  if (errors['imageData'] || errors['imageContentType']) {
    imageError = 'Image is required';
  }
  
  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Create Discipline</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/discipline'>&lt; Disciplines</ColoredLink>
    </AuthenticatedSubheader>
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <label>Year</label>
      <Select
      options={selectOptions}
      value={selectOptions[selectedYearIndex]}
      onChange={(option) => {setSelectedYearIndex(option!.value)}}
      />

      <ValidatedInput type='text' fieldName='name' register={register} errors={errors}/>

      <ImageSection>
        <Conditional on={imageSrc}>
          <Image src={imageSrc!}/>
        </Conditional>
        <input type="file" onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            const file: File = e.target.files[0];
            setImageFile(file);
            setImageSrc(URL.createObjectURL(file));            
          }
        }}></input>
        <Conditional on={imageError}>
          <ErrorLabel>{imageError}</ErrorLabel>
        </Conditional>
      </ImageSection>

      <SpinnerButton type='submit' isLoading={isLoading}>
        Create
      </SpinnerButton>
      <Conditional on={apiError}>
        <ErrorLabel>
          {apiError?.message}
        </ErrorLabel>
      </Conditional>
    </FormContainer>
  </>;
}