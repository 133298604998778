import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { Location, } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

interface EnrichedLocation extends Location {
  detailUrl: string;
}

export function LocationListPanel() {
  const { locations } = useContextOrThrow(FestivalContext);

  const enrichedLocations: Array<EnrichedLocation> = new Array();

  for (const [id, location] of locations) {
    const detailUrl: string = `/location/${location.id}`;
    const enrichedLocation: EnrichedLocation = {
      ...location,
      detailUrl,
    };

    enrichedLocations.push(enrichedLocation);
  }

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedLocation>>>([
    {field: 'festivalYear', sort: 'asc'},
    {field: 'name'},
    {field: 'id'},
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Locations</AuthenticatedHeaderTitle>
      <ColoredLink to='/location/create'>Create...</ColoredLink>
    </AuthenticatedHeader>
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: 500 }} // the Data Grid will fill the size of the parent container
    >
        <AgGridReact
        rowData={enrichedLocations}
        columnDefs={colDefs}
        rowSelection="single"
        defaultColDef={{flex: 1}}
        />
    </div>
  </>
}