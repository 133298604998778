import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { IfElse, User, } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AuthContext } from "../contexts/AuthContext";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { SuperuserUserApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";
import styled from "styled-components";

interface EnrichedUser extends User {
  detailUrl: string;
}

const YearLink = styled(ColoredLink)`
  margin-left: 10px;
`

export function UserListPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [users, setUsers] = useState<Array<EnrichedUser>>(new Array());

  useAsyncMountEffect(async () => {
    const api: SuperuserUserApi = apiState!.userApi as SuperuserUserApi;

    try {
      const users: Array<User> = await api.getAllUsers();
      const enrichedUsers: Array<EnrichedUser> = users.map(user => {
        const detailUrl: string = `/artist/${user.id}`;

        return {
          ...user,
          detailUrl,
        };
      });

      setUsers(enrichedUsers);
    }
    catch (e) {
      toast(`Failed to fetch artists. Please try again later. (Reason: ${e}.)`);
    }

    setIsLoading(false);
  });

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedUser>>>([
    {field: 'email', sort: 'asc'},
    {field: 'id'},
    {
      field: 'festivalYears',
      cellRenderer: (params: any) => {
        const years: Array<number> = params.value;
        return <>
          {years.map(year => <YearLink to={`/festival/${year}`} key={year}>{year}</YearLink>)}
        </>
      },
      valueFormatter: p => `${p}`,  // unused, just suppresses warning
    },
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Artists</AuthenticatedHeaderTitle>
        <ColoredLink to='/artist/create'>Create...</ColoredLink>
      </AuthenticatedHeader>
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
          <AgGridReact
          rowData={users}
          columnDefs={colDefs}
          rowSelection="single"
          defaultColDef={{flex: 1}}
          />
      </div>
    </>
  </IfElse>
}