"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplaceObjectValues = void 0;
// NOTE: TOriginalValue should not be an object, otherwise recursion will break
// uses a simple `===` comparison for now. could add a custom comparator func in the future if necessary
function ReplaceObjectValues(input, originalValue, replacementValue) {
    const result = {};
    for (const key in input) {
        const value = input[key];
        let newValue;
        if (typeof value === 'object') {
            newValue = ReplaceObjectValues(value, originalValue, replacementValue);
        }
        else {
            if (value === originalValue) {
                newValue = replacementValue;
            }
            else {
                newValue = value;
            }
        }
        result[key] = newValue;
    }
    return result;
}
exports.ReplaceObjectValues = ReplaceObjectValues;
