import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { BaseError, Conditional, SuperuserCreateUser, User, ValidateSuperuserCreateUser } from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext, SuperuserApiState, UserApiState } from "../contexts/AuthContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { SuperuserUserApi, UserApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";

import Select from 'react-select';
import { FestivalContext } from "../contexts/FestivalContext";


function CreateUserValidatorWrapper(years: Array<number>) {
  function *CreateUserValidator(input: SuperuserCreateUser) {
    input.festivalYears = years;
    yield *ValidateSuperuserCreateUser(input);
  }

  return CreateUserValidator;
}


export function UserCreatePanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals } = useContextOrThrow(FestivalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const [selectedYears, setSelectedYears] = useState<Array<number>>(new Array());

  const navigate = useNavigate();

  const resolver = useValidatorResolver(CreateUserValidatorWrapper(selectedYears));
  const { register, handleSubmit, formState: { errors } } = useForm<SuperuserCreateUser>({
    resolver,
  });

  const onSubmit: SubmitHandler<SuperuserCreateUser> = async (createUser) => {
    setIsLoading(true);

    createUser.festivalYears = selectedYears;

    try {
      const api = apiState!.userApi as SuperuserUserApi;
      const resultUser: User = await api.createUser(createUser);

      setIsLoading(false);
      toast('Success');
      navigate('/artist');
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  const selectOptions = festivals.map(festival => ({value: festival.year, label: `${festival.year}`}));
  if (selectOptions.length === 0) {
    selectOptions.push({value: 0, label: `N/A`});
  }
  
  const defaultOptions = selectedYears.sort().map(year => ({value: year, label: `${year}`}));
  
  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Create Artist</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/artist'>&lt; Artists</ColoredLink>
    </AuthenticatedSubheader>
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <ValidatedInput type='email' fieldName='email' register={register} errors={errors}/>
      <label>Years</label>
      <Select
      isMulti
      isClearable={false}
      options={selectOptions}
      value={defaultOptions}
      onChange={(options) => {
        const years = options.map(option => option.value).sort();
        setSelectedYears(years);
      }}
      />
      <SpinnerButton type='submit' isLoading={isLoading}>
        Create
      </SpinnerButton>
      <Conditional on={apiError}>
        <ErrorLabel>
          {apiError?.message}
        </ErrorLabel>
      </Conditional>
    </FormContainer>
  </>;
}