import { PropsWithChildren } from "react";

import {
  Festival,
  Discipline,
  Location,
} from '@sfiaf/common';

import { useState } from 'react';
import { FestivalContext, FestivalState } from "./FestivalContext";

export interface FestivalContextProviderProps extends PropsWithChildren {
}

export function FestivalContextProvider({ children }: FestivalContextProviderProps) {
  const [festivals, setFestivals] = useState<Array<Festival>>(new Array());
  const [disciplines, setDisciplines] = useState<Map<number, Discipline>>(new Map());
  const [disciplinesByYear, setDisciplinesByYear] = useState<Map<number, Array<Discipline>>>(new Map());
  const [locations, setLocations] = useState<Map<number, Location>>(new Map());
  const [locationsByYear, setLocationsByYear] = useState<Map<number, Array<Location>>>(new Map());

  const value: FestivalState = {
    festivals,
    setFestivals,
    disciplines,
    setDisciplines,
    disciplinesByYear,
    setDisciplinesByYear,
    locations,
    setLocations,
    locationsByYear,
    setLocationsByYear,
  }

  return (
    <FestivalContext.Provider value={value}>
      {children}
    </FestivalContext.Provider>
  )
}