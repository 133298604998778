import styled from "styled-components";

export enum AuthenticatedPanel {
  ShowList = 'ShowList',
  ShowDetails = 'ShowDetails',
};

export enum SuperuserAuthenticatedPanel {
  ShowCreate = 'ShowCreate',
  FestivalList = 'FestivalList',
  FestivalDetails = 'FestivalDetails',
  FestivalCreate = 'FestivalCreate',
  DisciplineList = 'DisciplineList',
  DisciplineDetails = 'DisciplineDetails',
  DisciplineCreate = 'DisciplineCreate',
  LocationList = 'LocationList',
  LocationDetails = 'LocationDetails',
  LocationCreate = 'LocationCreate',
  SuperuserList = 'SuperuserList',
  SuperuserDetails = 'SuperuserDetails',
  SuperuserCreate = 'SuperuserCreate',
  UserList = 'UserList',
  UserDetails = 'UserDetails',
  UserCreate = 'UserCreate',
};