"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeleteDiscipline = exports.ValidateUpdateDiscipline = exports.ValidateCreateDiscipline = exports.BuildDisciplineSlug = void 0;
const ModelCleaner_1 = require("./ModelCleaner");
const validator_1 = require("../validator");
const Festival_1 = require("./Festival");
const ImageUpload_1 = require("./ImageUpload");
const _ = __importStar(require("lodash"));
function BuildDisciplineSlug(year, name) {
    const snakeCaseName = _.snakeCase(name);
    const slug = `${year}_${snakeCaseName}`;
    return slug;
}
exports.BuildDisciplineSlug = BuildDisciplineSlug;
function* ValidateCreateDiscipline(createDisciplineInput) {
    const createDiscipline = (0, ModelCleaner_1.CleanModel)(createDisciplineInput);
    yield* (0, validator_1.ValidateRequiredProperty)(createDiscipline, 'name');
    yield* (0, validator_1.ValidateRequiredProperty)(createDiscipline, 'festivalYear');
    yield* (0, validator_1.ValidateRequiredProperty)(createDiscipline, 'imageData');
    yield* (0, validator_1.ValidateRequiredProperty)(createDiscipline, 'imageContentType');
    const { name, // TODO: add more decisions about name length etc.
    festivalYear, imageData, // TODO: honestly should have binary stream instead of base64
    imageContentType, // TODO: do mimetype checks
     } = createDiscipline;
    yield* (0, Festival_1.ValidateFestivalYear)(festivalYear);
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'name');
    return {
        name,
        festivalYear,
        imageData,
        imageContentType,
    };
}
exports.ValidateCreateDiscipline = ValidateCreateDiscipline;
function* ValidateUpdateDiscipline(updateDisciplineInput) {
    const updateDiscipline = (0, ModelCleaner_1.CleanModel)(updateDisciplineInput);
    yield* (0, validator_1.ValidateRequiredProperty)(updateDiscipline, 'id');
    yield* (0, validator_1.ValidateRequiredProperty)(updateDiscipline, 'name');
    yield* (0, ImageUpload_1.ValidatePartialImageUpload)(updateDiscipline);
    const { id, name, imageData, imageContentType, } = updateDiscipline;
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'name');
    return {
        id,
        name,
        imageData,
        imageContentType,
    };
}
exports.ValidateUpdateDiscipline = ValidateUpdateDiscipline;
function* ValidateDeleteDiscipline(deleteDisciplineInput) {
    const deleteDiscipline = (0, ModelCleaner_1.CleanModel)(deleteDisciplineInput);
    yield* (0, validator_1.ValidateRequiredProperty)(deleteDiscipline, 'id');
    const { id, destinationDisciplineId, // TODO: add type validators
     } = deleteDiscipline;
    return {
        id,
        destinationDisciplineId,
    };
}
exports.ValidateDeleteDiscipline = ValidateDeleteDiscipline;
