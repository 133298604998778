"use strict";
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i;
    function verb(n) { if (g[n]) i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __asyncDelegator = (this && this.__asyncDelegator) || function (o) {
    var i, p;
    return i = {}, verb("next"), verb("throw", function (e) { throw e; }), verb("return"), i[Symbol.iterator] = function () { return this; }, i;
    function verb(n, f) { i[n] = o[n] ? function (v) { return (p = !p) ? { value: __await(o[n](v)), done: false } : f ? f(v) : v; } : f; }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YieldMutateInputWrapper = exports.YieldMutate = exports.YieldMutateSyncInputWrapper = exports.YieldMutateSync = void 0;
const GeneratorExecutor_1 = require("./GeneratorExecutor");
function* YieldMutateSync(generator, yieldMutator = (input) => input, returnMutator = (input) => input, nextValueProvider) {
    const { yieldValues, returnValue, } = (0, GeneratorExecutor_1.ExecuteGeneratorSync)(generator, nextValueProvider);
    for (let i = 0; i < yieldValues.length; i++) {
        const yieldValue = yieldValues[i];
        const finalYieldValue = yieldMutator(yieldValue, i);
        yield finalYieldValue;
    }
    const finalReturnValue = returnMutator(returnValue);
    return finalReturnValue;
}
exports.YieldMutateSync = YieldMutateSync;
function YieldMutateSyncInputWrapper(generator, yieldMutator = (input) => input, returnMutator = (input) => input, nextValueProvider) {
    return function* (input) {
        const returnValue = yield* YieldMutateSync(generator(input), yieldMutator, returnMutator, nextValueProvider);
        return returnValue;
    };
}
exports.YieldMutateSyncInputWrapper = YieldMutateSyncInputWrapper;
function YieldMutate(generator, yieldMutator = (input) => input, returnMutator = (input) => input, nextValueProvider) {
    return __asyncGenerator(this, arguments, function* YieldMutate_1() {
        const { yieldValues, returnValue, } = yield __await((0, GeneratorExecutor_1.ExecuteGenerator)(generator, nextValueProvider));
        for (let i = 0; i < yieldValues.length; i++) {
            const yieldValue = yieldValues[i];
            const finalYieldValue = yield __await(yieldMutator(yieldValue, i));
            yield yield __await(finalYieldValue);
        }
        const finalReturnValue = yield __await(returnMutator(returnValue));
        return yield __await(finalReturnValue);
    });
}
exports.YieldMutate = YieldMutate;
function YieldMutateInputWrapper(generator, yieldMutator = (input) => input, returnMutator = (input) => input, nextValueProvider) {
    return function (input) {
        return __asyncGenerator(this, arguments, function* () {
            const returnValue = yield __await(yield* __asyncDelegator(__asyncValues(YieldMutate(generator(input), yieldMutator, returnMutator, nextValueProvider))));
            return yield __await(returnValue);
        });
    };
}
exports.YieldMutateInputWrapper = YieldMutateInputWrapper;
