"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeleteLocation = exports.ValidateUpdateLocation = exports.ValidateCreateLocation = void 0;
const ModelCleaner_1 = require("./ModelCleaner");
const validator_1 = require("../validator");
const Festival_1 = require("./Festival");
function* ValidateCreateLocation(input) {
    const createLocation = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(createLocation, 'name');
    yield* (0, validator_1.ValidateRequiredProperty)(createLocation, 'address');
    yield* (0, validator_1.ValidateRequiredProperty)(createLocation, 'festivalYear');
    const { name, address, festivalYear, } = createLocation;
    yield* (0, Festival_1.ValidateFestivalYear)(festivalYear);
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'name');
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'address');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'address');
    return {
        name,
        address,
        festivalYear,
    };
}
exports.ValidateCreateLocation = ValidateCreateLocation;
function* ValidateUpdateLocation(input) {
    const updateLocation = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(updateLocation, 'id');
    yield* (0, validator_1.ValidateRequiredProperty)(updateLocation, 'name');
    yield* (0, validator_1.ValidateRequiredProperty)(updateLocation, 'address');
    const { id, name, address, } = updateLocation;
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'name');
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'address');
    yield* (0, validator_1.ValidateMaxLength)(name, 30, 'address');
    return {
        id,
        name,
        address,
    };
}
exports.ValidateUpdateLocation = ValidateUpdateLocation;
function* ValidateDeleteLocation(input) {
    const deleteLocation = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(deleteLocation, 'id');
    const { id } = deleteLocation;
    return {
        id,
    };
}
exports.ValidateDeleteLocation = ValidateDeleteLocation;
