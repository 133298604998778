"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecuteGenerator = exports.ExecuteGeneratorSync = void 0;
function ExecuteGeneratorSync(generator, nextValueProvider) {
    const yieldValues = new Array();
    let returnValue = undefined;
    let done = false;
    while (!done) {
        const incompleteResult = {
            yieldValues,
            returnValue,
        };
        let input;
        if (nextValueProvider != null) {
            input = nextValueProvider(incompleteResult, false);
        }
        else {
            input = [];
        }
        const { value, done: doneResult } = generator.next(...input);
        done = doneResult;
        if (done === true) {
            returnValue = value;
        }
        else {
            yieldValues.push(value);
        }
    }
    const result = {
        yieldValues,
        returnValue: returnValue,
    };
    return result;
}
exports.ExecuteGeneratorSync = ExecuteGeneratorSync;
function ExecuteGenerator(generator, nextValueProvider) {
    return __awaiter(this, void 0, void 0, function* () {
        const yieldValues = new Array();
        let returnValue = undefined;
        let done = false;
        while (!done) {
            const incompleteResult = {
                yieldValues,
                returnValue,
            };
            let input;
            if (nextValueProvider != null) {
                input = yield nextValueProvider(incompleteResult, false);
            }
            else {
                input = [];
            }
            const { value, done: doneResult } = yield generator.next(...input);
            done = doneResult;
            if (done === true) {
                returnValue = value;
            }
            else {
                yieldValues.push(value);
            }
        }
        const result = {
            yieldValues,
            returnValue: returnValue,
        };
        return result;
    });
}
exports.ExecuteGenerator = ExecuteGenerator;
