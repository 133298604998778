import styled from "styled-components";

export const AuthenticatedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AuthenticatedHeaderTitle = styled.h2`
  margin-left: 20px;
  margin-right: 50px;
`;

export const AuthenticatedSubheader = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
`;