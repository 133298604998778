"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateUpdateFestival = exports.ValidateCreateFestival = exports.ValidateFestivalYear = exports.BuildFestivalSlug = void 0;
const ModelCleaner_1 = require("./ModelCleaner");
const validator_1 = require("../validator");
function BuildFestivalSlug(year) {
    return `sfiaf_${year}`;
}
exports.BuildFestivalSlug = BuildFestivalSlug;
function* ValidateFestivalYear(year) {
    yield* (0, validator_1.ValidateInteger)(year, 'year');
    yield* (0, validator_1.ValidateRange)(year, 2025, 2100, 'year');
    return year;
}
exports.ValidateFestivalYear = ValidateFestivalYear;
function* ValidateCreateFestival(createFestivalInput) {
    const createFestival = (0, ModelCleaner_1.CleanModel)(createFestivalInput);
    yield* (0, validator_1.ValidateRequiredProperty)(createFestival, 'year');
    yield* (0, validator_1.ValidateRequiredProperty)(createFestival, 'startDate');
    yield* (0, validator_1.ValidateRequiredProperty)(createFestival, 'endDate'); // TODO: create date validator
    const { year, startDate, endDate, } = createFestival;
    yield* ValidateFestivalYear(year);
    yield* (0, validator_1.ValidateDateString)(startDate, 'startDate');
    yield* (0, validator_1.ValidateDateString)(startDate, 'endDate');
    return {
        year,
        startDate,
        endDate,
    };
}
exports.ValidateCreateFestival = ValidateCreateFestival;
function* ValidateUpdateFestival(updateFestivalInput) {
    const updateFestival = (0, ModelCleaner_1.CleanModel)(updateFestivalInput);
    yield* (0, validator_1.ValidateRequiredProperty)(updateFestival, 'year');
    yield* (0, validator_1.ValidateRequiredProperty)(updateFestival, 'startDate');
    yield* (0, validator_1.ValidateRequiredProperty)(updateFestival, 'endDate');
    yield* (0, validator_1.ValidateRequiredProperty)(updateFestival, 'editable');
    const { year, startDate, endDate, editable, } = updateFestival;
    yield* ValidateFestivalYear(year);
    yield* (0, validator_1.ValidateDateString)(startDate, 'startDate');
    yield* (0, validator_1.ValidateDateString)(startDate, 'endDate');
    return {
        year,
        startDate,
        endDate,
        editable,
    };
}
exports.ValidateUpdateFestival = ValidateUpdateFestival;
