"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeleteShow = exports.ValidateSuperuserUpdateShow = exports.ValidateUpdateShow = exports.ValidateSuperuserCreateShow = exports.ValidateCreateShow = exports.ValidateUpsertShowBase = exports.ValidateUpsertShowCredit = exports.SortShowCredits = exports.BuildShowSlug = void 0;
const Performance_1 = require("./Performance");
const ModelCleaner_1 = require("./ModelCleaner");
const validator_1 = require("../validator");
const ImageUpload_1 = require("./ImageUpload");
const error_1 = require("../error");
const util_1 = require("../util");
const _ = __importStar(require("lodash"));
function BuildShowSlug(year, name) {
    const slugSpace = `${year} show ${name}`;
    const slug = _.snakeCase(slugSpace);
    return slug;
}
exports.BuildShowSlug = BuildShowSlug;
function SortShowCredits(showCredits) {
    const sortedShowCredits = showCredits
        .sort((a, b) => a.sortOrder - b.sortOrder);
    return sortedShowCredits;
}
exports.SortShowCredits = SortShowCredits;
function* ValidateUpsertShowCredit(input) {
    const showCredit = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(showCredit, 'name');
    const { name, description, bio, } = showCredit;
    yield* (0, validator_1.ValidateMinLength)(name, 1, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 50, 'name');
    if (description != null) {
        yield* (0, validator_1.ValidateMinLength)(description, 1, 'description');
        yield* (0, validator_1.ValidateMaxLength)(description, 200, 'description');
    }
    if (bio != null) {
        yield* (0, validator_1.ValidateMinLength)(bio, 1, 'bio');
        yield* (0, validator_1.ValidateMaxLength)(bio, 1000, 'bio');
    }
    return {
        name,
        description,
        bio,
    };
}
exports.ValidateUpsertShowCredit = ValidateUpsertShowCredit;
function* ValidateUpsertShowBase(input) {
    const upsertShowBase = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'name');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'intermission');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'duration');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'artist');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'disciplineId');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'locationId');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'countryAbbreviation');
    yield* (0, validator_1.ValidateRequiredProperty)(upsertShowBase, 'showCredits');
    const { name, duration, intermission, baseTicketPrice, descOneHundred, descOneHundredIntl, descFifty, statusMessage, productionName, productionEmail, productionPhone, primaryName, primaryEmail, primaryPhone, fundingCredits, artist, artistHistory, sampleUrl: sampleUrlInput, website: websiteInput, facebook: facebookInput, instagram: instagramInput, twitter: twitterInput, disciplineId, locationId, countryAbbreviation, performances: performancesInput, showCredits: showCreditsInput, } = input;
    yield* (0, validator_1.ValidateMinLength)(name, 3, 'name');
    yield* (0, validator_1.ValidateMaxLength)(name, 50, 'name');
    yield* (0, validator_1.ValidateNonnull)(duration, 'duration');
    yield* (0, validator_1.ValidateRange)(duration, 0, 1440, 'duration'); // 60 min * 24 hours
    if (baseTicketPrice != null) {
        yield* (0, validator_1.ValidateMinLength)(baseTicketPrice, 1, 'baseTicketPrice');
        yield* (0, validator_1.ValidateMaxLength)(baseTicketPrice, 200, 'baseTicketPrice');
    }
    yield* (0, validator_1.ValidateMinLength)(artist, 3, 'artist');
    yield* (0, validator_1.ValidateMaxLength)(artist, 50, 'artist');
    yield* (0, validator_1.ValidateInteger)(disciplineId, 'disciplineId');
    yield* (0, validator_1.ValidateInteger)(locationId, 'locationId');
    if (descOneHundred != null) {
        yield* (0, validator_1.ValidateMinLength)(descOneHundred, 3, 'descOneHundred');
        yield* (0, validator_1.ValidateMaxLength)(descOneHundred, 10000, 'descOneHundred');
        yield* (0, validator_1.ValidateMaxWordLength)(descOneHundred, 1000, 'descOneHundred');
    }
    if (descOneHundredIntl != null) {
        yield* (0, validator_1.ValidateMinLength)(descOneHundredIntl, 3, 'descOneHundredIntl');
        yield* (0, validator_1.ValidateMaxLength)(descOneHundredIntl, 10000, 'descOneHundredIntl');
        yield* (0, validator_1.ValidateMaxWordLength)(descOneHundredIntl, 1000, 'descOneHundredIntl');
    }
    if (descFifty != null) {
        yield* (0, validator_1.ValidateMinLength)(descFifty, 3, 'descFifty');
        yield* (0, validator_1.ValidateMaxLength)(descFifty, 5000, 'descFifty');
        yield* (0, validator_1.ValidateMaxWordLength)(descFifty, 500, 'descFifty');
    }
    if (statusMessage != null) {
        yield* (0, validator_1.ValidateMinLength)(statusMessage, 3, 'statusMessage');
        yield* (0, validator_1.ValidateMaxLength)(statusMessage, 1000, 'statusMessage');
        yield* (0, validator_1.ValidateMaxWordLength)(statusMessage, 100, 'statusMessage');
    }
    if (productionName != null) {
        yield* (0, validator_1.ValidateMinLength)(productionName, 3, 'productionName');
        yield* (0, validator_1.ValidateMaxLength)(productionName, 100, 'productionName');
    }
    if (productionEmail != null) {
        yield* (0, validator_1.ValidateMinLength)(productionEmail, 3, 'productionEmail');
        yield* (0, validator_1.ValidateMaxLength)(productionEmail, 50, 'productionEmail');
        yield* (0, validator_1.ValidateEmail)(productionEmail, 'productionEmail');
    }
    if (productionPhone != null) {
        yield* (0, validator_1.ValidateMinLength)(productionPhone, 3, 'productionPhone');
        yield* (0, validator_1.ValidateMaxLength)(productionPhone, 30, 'productionPhone');
    }
    if (primaryName != null) {
        yield* (0, validator_1.ValidateMinLength)(primaryName, 3, 'primaryName');
        yield* (0, validator_1.ValidateMaxLength)(primaryName, 100, 'primaryName');
    }
    if (primaryEmail != null) {
        yield* (0, validator_1.ValidateMinLength)(primaryEmail, 3, 'primaryEmail');
        yield* (0, validator_1.ValidateMaxLength)(primaryEmail, 50, 'primaryEmail');
        yield* (0, validator_1.ValidateEmail)(primaryEmail, 'primaryEmail');
    }
    if (primaryPhone != null) {
        yield* (0, validator_1.ValidateMinLength)(primaryPhone, 3, 'primaryPhone');
        yield* (0, validator_1.ValidateMaxLength)(primaryPhone, 30, 'primaryPhone');
    }
    if (fundingCredits != null) {
        yield* (0, validator_1.ValidateMinLength)(fundingCredits, 3, 'fundingCredits');
        yield* (0, validator_1.ValidateMaxLength)(fundingCredits, 100, 'fundingCredits');
    }
    if (artistHistory != null) {
        yield* (0, validator_1.ValidateMinLength)(artistHistory, 3, 'artistHistory');
        yield* (0, validator_1.ValidateMaxLength)(artistHistory, 500, 'artistHistory');
    }
    let sampleUrl;
    if (sampleUrlInput != null) {
        sampleUrl = yield* (0, validator_1.ValidateUrl)(sampleUrlInput, 'sampleUrl');
    }
    let website;
    if (websiteInput != null) {
        website = yield* (0, validator_1.ValidateUrl)(websiteInput, 'website');
    }
    let facebook;
    if (facebookInput != null) {
        facebook = yield* (0, validator_1.ValidateUrl)(facebookInput, 'facebook');
    }
    let instagram;
    if (instagramInput != null) {
        instagram = yield* (0, validator_1.ValidateUrl)(instagramInput, 'instagram');
    }
    let twitter;
    if (twitterInput != null) {
        twitter = yield* (0, validator_1.ValidateUrl)(twitterInput, 'twitter');
    }
    const performances = yield* (0, util_1.YieldMap)(performancesInput, Performance_1.ValidateUpsertPerformance, (input, mapInputIndex) => {
        const replacement = new error_1.ValidationError(input.message, `performance.${input.data.fieldName}-${mapInputIndex}`); // bit of a hack for now to make the fieldNames of the ValidationErrors uniquely identifiable in the FE per field
        return replacement;
    });
    if (performances.length === 0) {
        yield new error_1.ValidationError('There must be at least one performance for a show.', 'performances');
    }
    const showCredits = yield* (0, util_1.YieldMap)(showCreditsInput, ValidateUpsertShowCredit, (input, mapInputIndex) => {
        const replacement = new error_1.ValidationError(input.message, `showCredit.${input.data.fieldName}-${mapInputIndex}`);
        return replacement;
    });
    return {
        name,
        duration,
        intermission,
        baseTicketPrice,
        descOneHundred,
        descOneHundredIntl,
        descFifty,
        statusMessage,
        productionName,
        productionEmail,
        productionPhone,
        primaryName,
        primaryEmail,
        primaryPhone,
        fundingCredits,
        artist,
        artistHistory,
        sampleUrl,
        website,
        facebook,
        instagram,
        twitter,
        disciplineId,
        locationId,
        countryAbbreviation,
        performances,
        showCredits,
    };
}
exports.ValidateUpsertShowBase = ValidateUpsertShowBase;
function* ValidateCreateShow(input) {
    const createShow = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, ImageUpload_1.ValidateImageUpload)(createShow);
    const imageUpload = (0, ImageUpload_1.ExtractImageUpload)(createShow);
    const upsertShowBase = yield* ValidateUpsertShowBase(createShow);
    return Object.assign(Object.assign({}, imageUpload), upsertShowBase);
}
exports.ValidateCreateShow = ValidateCreateShow;
function* ValidateSuperuserCreateShow(input) {
    const superuserCreateShow = (0, ModelCleaner_1.CleanModel)(input);
    const { userId, ebPage, } = superuserCreateShow;
    if (ebPage != null) {
        yield* (0, validator_1.ValidateUrl)(ebPage, 'ebPage');
    }
    const createShow = yield* ValidateCreateShow(superuserCreateShow);
    return Object.assign({ userId,
        ebPage }, createShow);
}
exports.ValidateSuperuserCreateShow = ValidateSuperuserCreateShow;
function* ValidateUpdateShow(input) {
    const updateShow = (0, ModelCleaner_1.CleanModel)(input);
    const { id } = updateShow;
    yield* (0, validator_1.ValidateRequiredProperty)(updateShow, 'id');
    yield* (0, ImageUpload_1.ValidatePartialImageUpload)(updateShow);
    const imageUpload = (0, ImageUpload_1.ExtractPartialImageUpload)(updateShow);
    const upsertShowBase = yield* ValidateUpsertShowBase(updateShow);
    return Object.assign(Object.assign({ id }, imageUpload), upsertShowBase);
}
exports.ValidateUpdateShow = ValidateUpdateShow;
function* ValidateSuperuserUpdateShow(input) {
    const superuserUpdateShow = (0, ModelCleaner_1.CleanModel)(input);
    const { userId, ebPage, } = superuserUpdateShow;
    yield* (0, validator_1.ValidateRequiredProperty)(superuserUpdateShow, 'userId');
    const updateShow = yield* ValidateUpdateShow(superuserUpdateShow);
    if (ebPage != null) {
        yield* (0, validator_1.ValidateUrl)(ebPage, 'ebPage');
    }
    return Object.assign({ userId,
        ebPage }, updateShow);
}
exports.ValidateSuperuserUpdateShow = ValidateSuperuserUpdateShow;
function* ValidateDeleteShow(input) {
    const deleteShow = (0, ModelCleaner_1.CleanModel)(input);
    yield* (0, validator_1.ValidateRequiredProperty)(deleteShow, 'id');
    const { id } = deleteShow;
    return {
        id,
    };
}
exports.ValidateDeleteShow = ValidateDeleteShow;
