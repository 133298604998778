import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredALink, ColoredLink } from "../components/ColoredLink";
import {
  BaseError,
  BuildShowSlug,
  Conditional,
  Country,
  DeleteShow,
  Discipline,
  Festival,
  IfElse,
  Location,
  ReplacedObjectValue,
  Show,
  SortPerformances,
  SortShowCredits,
  Suffix,
  SuperuserUpdateShow,
  UpsertPerformance,
  UpsertShowCredit,
  User,
  UserType,
  ValidateSuperuserUpdateShow,
  ValidateUpdateShow,
  ValidationError,
} from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext } from "../contexts/AuthContext";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { SuperuserShowApi, SuperuserUserApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedInput, ValidatedTextArea } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";
import { toBase64 } from "../utils/toBase64";
import Select, { SingleValue } from 'react-select';
import styled from "styled-components";
import { CenteredContentContainer, FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { CountryContext } from "../contexts/CountryContext";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";
import Moment from 'moment';
import { Environment } from "../environment/Environment";
import { DeleteResource } from "./DeleteResource";

type PerformanceErrorCollection =
Suffix<
  ReplacedObjectValue<UpsertPerformance, ValidationError | undefined>,
  'Error'
>;

type ShowCreditErrorCollection =
Suffix<
  ReplacedObjectValue<UpsertShowCredit, ValidationError | undefined>,
  'Error'
>;

const Image = styled.img`
  object-fit: cover;
  aspect-ratio: 3/2;
  max-width: 600px;
`;

const ImageSection = styled(CenteredContentContainer)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const ImageLabel = styled.label`
  margin-top: 20px;
`

const HorizontalContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row;
`;

const PerformancesSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PerformanceContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ShowCreditsSection = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ShowCreditContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${Theme.color.gray};
  padding: 20px;
`;


function SuperuserUpdateShowValidatorWrapper(
  userId: number | null,
  disciplineId: number,
  locationId: number,
  countryAbbreviation: string,
  performances: Array<UpsertPerformance>,
  showCredits: Array<UpsertShowCredit>,
  imageFile: File | null
) {
  async function *SuperuserUpdateShowValidator(input: SuperuserUpdateShow) {
    if (imageFile != null) {
      input.imageContentType = imageFile.type;
      const imageBase64Full: string = (await toBase64(imageFile)) as string;
      const imageBase64: string = (imageBase64Full as string).split('base64,')[1];
      input.imageData = imageBase64;
    }

    input.userId = userId;

    input.disciplineId = disciplineId;
    input.locationId = locationId;
    input.countryAbbreviation = countryAbbreviation;
    input.performances = performances;
    input.showCredits = showCredits;

    yield *ValidateSuperuserUpdateShow(input);
  }

  return SuperuserUpdateShowValidator;
}

export function ShowDetailsPanel() {
  const { apiState, authState } = useContextOrThrow(AuthContext);
  const { countries } = useContextOrThrow(CountryContext);
  const { festivals, disciplines, disciplinesByYear, locationsByYear } = useContextOrThrow(FestivalContext);

  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(0);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number | null>(0);
  const [selectedDisciplineIndex, setSelectedDisciplineIndex] = useState<number>(0);
  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState<number>(237); // US by default

  const defaultPerformance: UpsertPerformance = {
    date: Moment().format('YYYY-MM-DD'),
    time: Moment().format('HH:mm'),
  };
  const [performances, setPerformances] = useState<Array<UpsertPerformance>>([defaultPerformance]);
  const [showCredits, setShowCredits] = useState<Array<UpsertShowCredit>>(new Array());

  const [users, setUsers] = useState<Array<User>>(new Array());

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const [show, setShow] = useState<Show | null>(null);

  const showIdString: string | undefined = useParams()['*'];

  const showId: number = Number(showIdString);

  const navigate = useNavigate();

  const isSuperuser: boolean = authState!.userType === UserType.Superuser;

  useAsyncMountEffect(async () => {
    try {
      const showApi = apiState!.showApi;
      const show = await showApi.getShow(showId);
      setShow(show);

      const {
        userId,
        disciplineId,
        locationId,
        performances,
        showCredits,
      } = show;

      if (isSuperuser) {
        const userApi = apiState!.userApi as SuperuserUserApi;
        const fetchedUsers = await userApi.getAllUsers();  // TODO: set up a getUsersForYear endpoint and refetch
        setUsers(fetchedUsers);

        for (let i=0; i<fetchedUsers.length; i++) {
          const user: User = fetchedUsers[i];
          if (user.id === userId) {
            setSelectedUserIndex(i);
            break;
          }
        }
      }
      else {
        const currentUser: User = authState!.currentUser as User;
        const fetchedUsers = [currentUser];
        setUsers(fetchedUsers);
        setSelectedUserIndex(0);
      }

      const discipline: Discipline | undefined = disciplines.get(disciplineId);
      if (discipline == null) {
        toast(`Discipline with id=${disciplineId} not found. Please contact the admins.`);
        navigate('/show');
        return;
      }

      const {
        festivalYear
      } = discipline;

      for (let i=0; i<festivals.length; i++) {
        const festival: Festival = festivals[i];
        if (festival.year === festivalYear) {
          setSelectedYearIndex(i);
          break;
        }
      }

      const disciplinesForYear: Array<Discipline> = disciplinesByYear.get(festivalYear)!;
      for (let i=0; i<disciplinesForYear.length; i++) {
        const discipline: Discipline = disciplinesForYear[i];
        if (discipline.id === disciplineId) {
          setSelectedDisciplineIndex(i);
          break;
        }
      }

      const locationsForYear: Array<Location> = locationsByYear.get(festivalYear)!;
      for (let i=0; i<locationsForYear.length; i++) {
        const location: Location = locationsForYear[i];
        if (location.id === locationId) {
          setSelectedLocationIndex(i);
          break;
        }
      }

      const sortedPerformances = SortPerformances(performances);
      setPerformances(sortedPerformances);

      const sortedShowCredits = SortShowCredits(showCredits);
      setShowCredits(sortedShowCredits);

      reset(show as SuperuserUpdateShow);
      setIsLoading(false);
    }
    catch (e) {
      toast(`Failed to fetch necessary data. Please try again later. (Reason: ${e}.)`);
      navigate('/show');
    }
  });

  const festival: Festival = festivals[selectedYearIndex];

  let user: User | null;
  if (selectedUserIndex != null) {
    user = users[selectedUserIndex];
  }
  else {
    user = null;
  }

  const festivalDisciplines: Array<Discipline> = disciplinesByYear.get(festival.year)!;
  const discipline: Discipline = festivalDisciplines[selectedDisciplineIndex];

  const festivalLocations: Array<Location> = locationsByYear.get(festival.year)!;
  const location: Location = festivalLocations[selectedLocationIndex];

  const country: Country = countries[selectedCountryIndex];

  const resolver = useValidatorResolver(
    SuperuserUpdateShowValidatorWrapper(
      user ? user.id : null,
      discipline ? discipline.id : -1,
      location ? location.id : -1,
      country.abbreviation,
      performances,
      showCredits,
      imageFile,
    )
  );
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<SuperuserUpdateShow>({
    resolver,
  });

  const onSubmit: SubmitHandler<SuperuserUpdateShow> = async (superuserUpdateShow) => {
    setIsSubmitting(true);

    if (isSuperuser) {
      superuserUpdateShow.userId = user ? user.id : null;
    }

    superuserUpdateShow.disciplineId = discipline.id;
    superuserUpdateShow.locationId = location.id;
    superuserUpdateShow.countryAbbreviation = country.abbreviation;
    superuserUpdateShow.performances = performances;
    superuserUpdateShow.showCredits = showCredits;

    try {
      if (imageFile != null) {
        superuserUpdateShow.imageContentType = imageFile.type;
        const imageBase64Full: string = (await toBase64(imageFile)) as string;
        const imageBase64: string = (imageBase64Full as string).split('base64,')[1];
        superuserUpdateShow.imageData = imageBase64;
      }

      const api = apiState!.showApi;
      const resultShow: Show = await api.updateShow(superuserUpdateShow);

      toast('Success');
      setIsSubmitting(false);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsSubmitting(false);
    }
  };

  const festivalSelectOptions = festivals.map((festival, index) => ({value: index, label: `${festival.year}`}));
  if (festivalSelectOptions.length === 0) {
    festivalSelectOptions.push({value: 0, label: `N/A`});
  }

  const disciplineSelectOptions = festivalDisciplines.map((discipline, index) => ({value: index, label: `${discipline.name}`}));
  if (disciplineSelectOptions.length === 0) {
    disciplineSelectOptions.push({value: 0, label: `(A discipline must be created for this festival year)`});
  }

  const locationSelectOptions = festivalLocations.map((location, index) => ({value: index, label: `${location.name} - ${location.address}`}));
  if (locationSelectOptions.length === 0) {
    locationSelectOptions.push({value: 0, label: `(A location must be created for this festival year)`});
  }

  const countrySelectOptions = countries.map((country, index) => ({value: index, label: `${country.name} (${country.abbreviation})`}));

  const userSelectOptions = users
    .filter(user => user.festivalYears.includes(festival.year))
    .map((user, index) => ({value: index, label: `${user.email}`}));

  const updateUserSelection = (option?: SingleValue<{value: number; label: string;}>) => {
    if (option != null) {
      setSelectedUserIndex(option.value);
      const user: User = users[option.value];
      setValue('productionEmail', user.email);
    }
    else {
      setSelectedUserIndex(null);
      setValue('productionEmail', undefined);
    }
  }

  let imageError: string | undefined;
  if (errors['imageData'] || errors['imageContentType']) {
    imageError = 'Image is required';
  }

  // quite a bit hacky, need to refactor this later with the validator approach
  const performanceErrors: Map<number, PerformanceErrorCollection> = new Map();
  for (let i=0; i<performances.length; i++) {
    const dateErrorKey: string = `performance.date-${i}`;
    const timeErrorKey: string = `performance.time-${i}`;

    const dateError: ValidationError | undefined = errors[dateErrorKey as keyof SuperuserUpdateShow] as unknown as ValidationError;
    const timeError: ValidationError | undefined = errors[timeErrorKey as keyof SuperuserUpdateShow] as unknown as ValidationError;

    const errorCollection: PerformanceErrorCollection = {
      dateError,
      timeError,
    };

    performanceErrors.set(i, errorCollection);
  }

  const showCreditErrors: Map<number, ShowCreditErrorCollection> = new Map();
  for (let i=0; i<showCredits.length; i++) {    
    const nameErrorKey: string = `showCredit.name-${i}`;
    const descriptionErrorKey: string = `showCredit.description-${i}`;
    const bioErrorKey: string = `showCredit.bio-${i}`;

    const nameError: ValidationError | undefined = errors[nameErrorKey as keyof SuperuserUpdateShow] as unknown as ValidationError;
    const descriptionError: ValidationError | undefined = errors[descriptionErrorKey as keyof SuperuserUpdateShow] as unknown as ValidationError;
    const bioError: ValidationError | undefined = errors[bioErrorKey as keyof SuperuserUpdateShow] as unknown as ValidationError;
  
    const errorCollection: ShowCreditErrorCollection = {
      nameError,
      descriptionError,
      bioError,
    };

    showCreditErrors.set(i, errorCollection);
  }

  let imageSource: string;
  if (imageSrc != null) {
    imageSource = imageSrc;
  }
  else if (show != null) {
    imageSource = `${Environment.cdnBaseUrl}/${show.imageKey}`;
  }
  else {
    imageSource = '';
  }

  let canSubmit: boolean;
  if (discipline != null && location != null) {
    canSubmit = true;
  }
  else {
    canSubmit = false;
  }

  const onDeletion = async (show: Show) => {
    const api = apiState!.showApi as SuperuserShowApi;
    const deleteShow: DeleteShow = {
      id: show.id,
    };
    
    await api.deleteShow(deleteShow);
  };

  let nbUrl: string = '';
  if (show != null) {
    const discipline: Discipline | undefined = disciplines.get(show.disciplineId);
    if (discipline == null) { // shouldn't happen, but just in case
      toast(`Show with id=${show.id} references non-existent discipline with id=${show.disciplineId}`);
    }
    const slug: string = BuildShowSlug(discipline!.festivalYear, show.name);
    nbUrl = `${Environment.nbBaseUrl}/${slug}`;
  }

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Update Show</AuthenticatedHeaderTitle>
      </AuthenticatedHeader>
      <AuthenticatedSubheader>
        <ColoredLink to='/show'>&lt; Shows</ColoredLink>
        <ColoredLink to={nbUrl} target="_blank">View in NB</ColoredLink>
        <DeleteResource resource={show!} onDeletion={onDeletion} redirect='/show'/>
      </AuthenticatedSubheader>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <label>Year*</label>
        <Select
        options={festivalSelectOptions}
        value={festivalSelectOptions[selectedYearIndex]}
        isDisabled={true}
        />

        <label>Discipline*</label>
        <Select
        options={disciplineSelectOptions}
        value={disciplineSelectOptions[selectedDisciplineIndex]}
        onChange={(option) => {setSelectedDisciplineIndex(option!.value)}}
        />

        <label>Location*</label>
        <Select
        options={locationSelectOptions}
        value={locationSelectOptions[selectedLocationIndex]}
        onChange={(option) => {setSelectedLocationIndex(option!.value)}}
        />

        <label>Artist Country*</label>
        <Select
        options={countrySelectOptions}
        value={countrySelectOptions[selectedCountryIndex]}
        onChange={(option) => {setSelectedCountryIndex(option!.value)}}
        />

        <ValidatedInput type='text' fieldName='name' title='Show Name' register={register} errors={errors}/>

        <label>Artist</label>
        <Select
        isClearable
        options={userSelectOptions}
        value={selectedUserIndex != null ? userSelectOptions[selectedUserIndex] : null}
        onChange={(option) => {updateUserSelection(option)}}
        isDisabled={!isSuperuser}
        />

        <ImageLabel>Image*</ImageLabel>
        <ImageSection>
          <Conditional on={imageSource}>
            <Image src={imageSource}/>
          </Conditional>
          <input type="file" onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file: File = e.target.files[0];
              setImageFile(file);
              setImageSrc(URL.createObjectURL(file));            
            }
          }}></input>
          <Conditional on={imageError}>
            <ErrorLabel>{imageError}</ErrorLabel>
          </Conditional>
        </ImageSection>
      
        <ValidatedInput type='text' fieldName='artist' title='Artist Display Name' register={register} errors={errors}/>

        <ValidatedInput type='number' fieldName='duration' title={'Duration (in minutes)'} register={register} errors={errors}/>
        <ValidatedInput type='checkbox' fieldName='intermission' register={register} errors={errors}/>
        <ValidatedInput optional type='text' fieldName='baseTicketPrice' title={'Ticket Prices (leave blank if event is free)'} register={register} errors={errors}/>

        <ValidatedInput optional type='text' fieldName='artistHistory' register={register} errors={errors}/>

        <ValidatedInput optional type='text' fieldName='fundingCredits' register={register} errors={errors}/>

        <ValidatedTextArea optional rows={8} fieldName='descOneHundred' title={'Description (~200 words)'} register={register} errors={errors}/>
        <ValidatedTextArea optional rows={8} fieldName='descOneHundredIntl' title={'International Language Description (~200 words)'} register={register} errors={errors}/>
        <ValidatedTextArea optional rows={4} fieldName='descFifty' title={'Short Description (~100 words)'} register={register} errors={errors}/>

        {/* <ValidatedInput type='text' fieldName='statusMessage' register={register} errors={errors}/> */}

        <ValidatedInput optional type='text' fieldName='productionName' register={register} errors={errors}/>
        <ValidatedInput optional type='email' fieldName='productionEmail' register={register} errors={errors}/>
        <ValidatedInput optional type='text' fieldName='productionPhone' register={register} errors={errors}/>

        <ValidatedInput optional type='text' fieldName='primaryName' register={register} errors={errors}/>
        <ValidatedInput optional type='email' fieldName='primaryEmail' register={register} errors={errors}/>
        <ValidatedInput optional type='text' fieldName='primaryPhone' register={register} errors={errors}/>

        <ValidatedInput optional type='url' fieldName='ebPage' register={register} errors={errors}/>

        <ValidatedInput optional type='url' fieldName='sampleUrl' register={register} errors={errors}/>
        <ValidatedInput optional type='url' fieldName='website' register={register} errors={errors}/>
        <ValidatedInput optional type='url' fieldName='facebook' register={register} errors={errors}/>
        <ValidatedInput optional type='url' fieldName='instagram' register={register} errors={errors}/>
        <ValidatedInput optional type='url' fieldName='twitter' register={register} errors={errors}/>

        <PerformancesSection>
          <HorizontalContainer>
            <label>Performances</label>
            <ColoredALink onClick={(e) => {
              e.preventDefault();
              const date: string = Moment().format('YYYY-MM-DD');
              const time: string = Moment().format('HH:mm');
              performances.push({date, time});
              setPerformances([...performances]); // need to make a new array to force react to recognize the state change for some reason (for array, it should at least be able to compare length)
            }}>+ Add</ColoredALink>
          </HorizontalContainer>
          {performances.map((p, i) => {
            const {
              dateError,
              timeError,
            } = performanceErrors.get(i)!;
            return (
            <PerformanceContainer key={i}>
              <label>Date</label>
              <input
              key={`performance-date-${i}`}
              type='date'
              value={p.date}
              onChange={(e) => {
                p.date = e.target.value;
                performances[i] = p;
                setPerformances([...performances]);
              }}
              />
              <Conditional on={dateError}>
                <ErrorLabel key={`performance-date-error-${i}`}>{dateError?.message}</ErrorLabel>
              </Conditional>
              <label>Time</label>
              <input
              key={`performance-time-${i}`}
              type='time'
              value={p.time}
              onChange={(e) => {
                p.time = e.target.value;
                performances[i] = p;
                setPerformances([...performances]);
              }}
              />
              <Conditional on={timeError}>
                <ErrorLabel key={`performance-time-error-${i}`}>{timeError?.message}</ErrorLabel>
              </Conditional>
              {/* prevent deletion of the 1st performance since at least one is always required */}
              <Conditional on={i > 0}>
                <ColoredALink key={`performance-remove-${i}`} onClick={(e) => {
                  e.preventDefault();
                  performances.splice(i, 1);  // remove single item at index i
                  setPerformances([...performances]); // need to make a new array to force react to recognize the state change for some reason (for array, it should at least be able to compare length)
                }}>Remove</ColoredALink>
              </Conditional>
            </PerformanceContainer>);
            })}
        </PerformancesSection>

        <ShowCreditsSection>
          <HorizontalContainer>
            <label>Show Credits</label>
            <ColoredALink onClick={(e) => {
              e.preventDefault();
              showCredits.push({name: ''});
              setShowCredits([...showCredits]);
            }}>+ Add</ColoredALink>
          </HorizontalContainer>
          {showCredits.map((s, i) => {
            const {
              nameError,
              descriptionError,
              bioError,
            } = showCreditErrors.get(i)!;
            return (
            <ShowCreditContainer key={i}>
              <label>Name*</label>
              <input
              key={`showCredit-name-${i}`}
              type='text'
              value={s.name}
              onChange={(e) => {
                s.name = e.target.value;
                showCredits[i] = s;
                setShowCredits([...showCredits]);
              }}
              />
              <Conditional on={nameError}>
                <ErrorLabel key={`show-credit-name-error-${i}`}>{nameError?.message}</ErrorLabel>
              </Conditional>
              <label>Bio</label>
              <textarea
              key={`showCredit-bio-${i}`}
              rows={4}
              value={s.bio || ''} // need the empty string to mark the input as controlled to react
              onChange={(e) => {
                s.bio = e.target.value;
                showCredits[i] = s;
                setShowCredits([...showCredits]);
              }}
              />
              <Conditional on={bioError}>
                <ErrorLabel key={`show-credit-bio-error-${i}`}>{bioError?.message}</ErrorLabel>
              </Conditional>
              <label>Description</label>
              <input
              key={`showCredit-description-${i}`}
              type='text'
              value={s.description || ''} // need the empty string to mark the input as controlled to react
              onChange={(e) => {
                s.description = e.target.value;
                showCredits[i] = s;
                setShowCredits([...showCredits]);
              }}
              />
              <Conditional on={descriptionError}>
                <ErrorLabel key={`show-credit-description-error-${i}`}>{descriptionError?.message}</ErrorLabel>
              </Conditional>
              <ColoredALink key={`showCredit-remove-${i}`} onClick={(e) => {
                e.preventDefault();
                showCredits.splice(i, 1);  // remove single item at index i
                setShowCredits([...showCredits]);
              }}>Remove</ColoredALink>
            </ShowCreditContainer>);
            })}
        </ShowCreditsSection>

        <SpinnerButton type='submit' isLoading={isSubmitting} disabled={!canSubmit}>
          Update
        </SpinnerButton>
        <Conditional on={!canSubmit}>
          <ErrorLabel>
            At least one discipline must be created for festival year {festival.year} before proceeding.
          </ErrorLabel>
        </Conditional>
        <Conditional on={apiError}>
          <ErrorLabel>
            {apiError?.message}
          </ErrorLabel>
        </Conditional>
      </FormContainer>
    </>
  </IfElse>
}