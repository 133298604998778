import { Environment } from "../environment/Environment";

import {
  SuperuserApi,
  SuperuserCountryApi,
  SuperuserDisciplineApi,
  SuperuserFestivalApi,
  SuperuserLocationApi,
  SuperuserShowApi,
  SuperuserUserApi,

  UserApi,
  UserCountryApi,
  UserDisciplineApi,
  UserFestivalApi,
  UserLocationApi,
  UserShowApi,
} from "@sfiaf/api";
import {
  BaseError,
  Superuser,
  SuperuserOAuthAccessToken,
  User,
  UserOAuthAccessToken,
  UserType,
} from "@sfiaf/common";

import { createContext } from "react";
import { useContextOrThrow } from "../utils/useContextOrThrow";



export const AuthStateKey: string = 'authState';


//**************************************************
//
//  Auth State
//
//**************************************************
// storable
export interface AuthState {
  userType: UserType;
  accessToken: UserOAuthAccessToken | SuperuserOAuthAccessToken;
  currentUser: User | Superuser;
};


//**************************************************
//
//  Api State
//
//**************************************************
export interface SuperuserApiState {
  superuserApi: SuperuserApi;
  countryApi: SuperuserCountryApi;
  disciplineApi: SuperuserDisciplineApi;
  festivalApi: SuperuserFestivalApi;
  locationApi: SuperuserLocationApi;
  showApi: SuperuserShowApi;
  userApi: SuperuserUserApi;
}

export interface UserApiState {
  userApi: UserApi;
  countryApi: UserCountryApi;
  disciplineApi: UserDisciplineApi;
  festivalApi: UserFestivalApi;
  locationApi: UserLocationApi;
  showApi: UserShowApi;
}



export function BuildSuperuserApiState(
  accessToken: string,
  errorMiddleware?: (e: any) => void,
  beBaseUrl: string = Environment.beBaseUrl,
): SuperuserApiState {
  const state: SuperuserApiState = {
    superuserApi: new SuperuserApi(beBaseUrl, accessToken, errorMiddleware),
    countryApi: new SuperuserCountryApi(beBaseUrl, accessToken, errorMiddleware),
    disciplineApi: new SuperuserDisciplineApi(beBaseUrl, accessToken, errorMiddleware),
    festivalApi: new SuperuserFestivalApi(beBaseUrl, accessToken, errorMiddleware),
    locationApi: new SuperuserLocationApi(beBaseUrl, accessToken, errorMiddleware),
    showApi: new SuperuserShowApi(beBaseUrl, accessToken, errorMiddleware),
    userApi: new SuperuserUserApi(beBaseUrl, accessToken, errorMiddleware),
  };

  return state;
}

export function BuildUserApiState(
  accessToken: string,
  errorMiddleware?: (e: any) => void,
  beBaseUrl: string = Environment.beBaseUrl,
): UserApiState {
  const state: UserApiState = {
    userApi: new UserApi(beBaseUrl, accessToken, errorMiddleware),
    countryApi: new UserCountryApi(beBaseUrl, accessToken, errorMiddleware),
    disciplineApi: new UserDisciplineApi(beBaseUrl, accessToken, errorMiddleware),
    festivalApi: new UserFestivalApi(beBaseUrl, accessToken, errorMiddleware),
    locationApi: new UserLocationApi(beBaseUrl, accessToken, errorMiddleware),
    showApi: new UserShowApi(beBaseUrl, accessToken, errorMiddleware),
  };

  return state;
}

//**************************************************
//
//  Context
//
//**************************************************
export type SetAccessTokenType = (
  accessToken: UserOAuthAccessToken | SuperuserOAuthAccessToken | null,
  userType?: UserType,
) => void;

export interface AuthContextState {
  authState: AuthState | null;
  setAuthState: (authState: AuthState | null) => void;
  apiState: SuperuserApiState | UserApiState | null;
}

// const DefaultAuthContextState: AuthContextState = {
//   authState: null,
//   apiState: null,
//   setAuthState: () => {},
// };

export const AuthContext = createContext<AuthContextState | null>(null);