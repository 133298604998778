"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllegalStateError = void 0;
const BaseError_1 = require("./BaseError");
const DefaultErrorMessage = 'Illegal state error';
class IllegalStateError extends BaseError_1.BaseError {
    constructor(message = DefaultErrorMessage) {
        super(message);
        this.statusCode = 400;
    }
}
exports.IllegalStateError = IllegalStateError;
