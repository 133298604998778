import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { BuildDisciplineSlug, Discipline, } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { Environment } from "../environment/Environment";
import styled from "styled-components";

const Image = styled.img`
  object-fit: contain;
  aspect-ratio: 3/2;
  width: 100%;
  height: 100%;
`;

interface EnrichedDiscipline extends Discipline {
  nbUrl: string;
  detailUrl: string;
}

export function DisciplineListPanel() {
  const { disciplines } = useContextOrThrow(FestivalContext);

  const enrichedDisciplines: Array<EnrichedDiscipline> = new Array();

  for (const [id, discipline] of disciplines) {
    const slug: string = BuildDisciplineSlug(discipline.festivalYear, discipline.name);
    const nbUrl: string = `${Environment.nbBaseUrl}/${slug}`;
    const detailUrl: string = `/discipline/${discipline.id}`;

    const enrichedDiscipline: EnrichedDiscipline = {
      ...discipline,
      nbUrl,
      detailUrl,
    }

    enrichedDisciplines.push(enrichedDiscipline);
  }

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedDiscipline>>>([
    {field: 'festivalYear', sort: 'asc'},
    {field: 'name'},
    {field: 'id'},
    {
      field: 'imageKey',
      headerName: 'Image',
      cellRenderer: (params: any) => {
        const imageUrl: string = `${Environment.cdnBaseUrl}/${params.value}`;
        return <ColoredLink to={imageUrl} target="_blank">
          <Image src={imageUrl}/>
        </ColoredLink>;
      }
    },
    {
      field: 'nbUrl',
      headerName: 'NB',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value} target="_blank">View</ColoredLink>;
      },
    },
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Disciplines</AuthenticatedHeaderTitle>
      <ColoredLink to='/discipline/create'>Create...</ColoredLink>
    </AuthenticatedHeader>
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: 500 }} // the Data Grid will fill the size of the parent container
    >
        <AgGridReact
        rowData={enrichedDisciplines}
        columnDefs={colDefs}
        rowSelection="single"
        defaultColDef={{flex: 1}}
        />
    </div>
  </>
}