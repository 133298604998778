"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserShowApi = exports.UserShowApi = void 0;
const BaseApi_1 = require("./BaseApi");
class SharedShowApi extends BaseApi_1.CredentialedBaseApi {
    getShow(showId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/show/get/${showId}`);
            return result;
        });
    }
    deleteShow(deleteShow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/show/delete', deleteShow);
            return result;
        });
    }
}
class UserShowApi extends SharedShowApi {
    getAllShowsForCurrentUserAndFestivalYear(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/show/festival/${year}`);
            return result;
        });
    }
    createShow(createShow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/show', createShow);
            return result;
        });
    }
    updateShow(updateShow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/show', updateShow);
            return result;
        });
    }
}
exports.UserShowApi = UserShowApi;
class SuperuserShowApi extends SharedShowApi {
    getAllShowsForFestivalYear(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/show/festival/${year}`);
            return result;
        });
    }
    getAllShowsForDiscipline(disciplineId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/show/discipline/${disciplineId}`);
            return result;
        });
    }
    getAllShowsForUser(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/show/user/${userId}`);
            return result;
        });
    }
    getAllShowsForLocation(locationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/show/location/${locationId}`);
            return result;
        });
    }
    createShow(superuserCreateShow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/show', superuserCreateShow);
            return result;
        });
    }
    updateShow(superuserUpdateShow) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/superuser/show', superuserUpdateShow);
            return result;
        });
    }
}
exports.SuperuserShowApi = SuperuserShowApi;
