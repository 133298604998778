"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserApi = exports.SuperuserLoginApi = void 0;
const BaseApi_1 = require("./BaseApi");
class SuperuserLoginApi extends BaseApi_1.BaseApi {
    sendMagicLink(email) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('POST', '/superuser/send_magic_link', { email });
        });
    }
    login(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/login', { token });
            return result;
        });
    }
}
exports.SuperuserLoginApi = SuperuserLoginApi;
class SuperuserApi extends BaseApi_1.CredentialedBaseApi {
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('DELETE', '/superuser/logout');
        });
    }
    getCurrentSuperuser() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/superuser/current');
            return result;
        });
    }
    getSuperuser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/get/${id}`);
            return result;
        });
    }
    getAllSuperusers() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/superuser');
            return result;
        });
    }
    createSuperuser(createSuperuser) {
        return __awaiter(this, void 0, void 0, function* () {
            const { email } = createSuperuser;
            const result = yield this.submitRequest('POST', '/superuser', { email });
            return result;
        });
    }
    updateSuperuser(updateSuperuser) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/update', updateSuperuser);
            return result;
        });
    }
    deleteSuperuser(deleteSuperuser) {
        return __awaiter(this, void 0, void 0, function* () {
            const { id } = deleteSuperuser;
            yield this.submitRequest('POST', '/superuser/delete', { id });
        });
    }
}
exports.SuperuserApi = SuperuserApi;
