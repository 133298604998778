import { PropsWithChildren } from "react";

import {
  Country,
} from '@sfiaf/common';

import { useState } from 'react';
import { CountryContext, CountryState } from "./CountryContext";

export interface CountryContextProviderProps extends PropsWithChildren {
}

export function CountryContextProvider({ children }: CountryContextProviderProps) {
  const [countries, setCountries] = useState<Array<Country>>(new Array());

  const value: CountryState = {
    countries,
    setCountries,
  };

  return (
    <CountryContext.Provider value={value}>
      {children}
    </CountryContext.Provider>
  )
}