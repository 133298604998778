"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateNonnull = void 0;
const error_1 = require("../error");
function* ValidateNonnull(input, name = 'input') {
    if (input == null) {
        yield new error_1.ValidationError(`The value must be non-null`, name);
    }
}
exports.ValidateNonnull = ValidateNonnull;
