"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtractIBaseError = exports.BaseError = void 0;
class BaseError extends Error {
    constructor() {
        super(...arguments);
        this.name = this.constructor.name;
    }
}
exports.BaseError = BaseError;
function ExtractIBaseError(baseError) {
    const { statusCode, name, message, data, } = baseError;
    const result = {
        statusCode,
        name,
        message,
        data,
    };
    return result;
}
exports.ExtractIBaseError = ExtractIBaseError;
