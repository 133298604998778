import { GetOptionalEnvVar, GetRequiredEnvVarOrExit } from '@sfiaf/common';

export enum EnvironmentType {
  Local = 'Local',
  Staging = 'Staging',
  Production = 'Production',
  Other = 'Other'
}

export interface IEnvironment {
  // Environment
  environmentType: EnvironmentType;

  feBaseUrl: string;
  beBaseUrl: string;

  nbBaseUrl: string;

  cdnBaseUrl: string;
}



const environmentString: string = GetRequiredEnvVarOrExit('REACT_APP_ENVIRONMENT');

if (Object.values(EnvironmentType).some((value: string) => value === environmentString) !== true) {
  throw new Error(`ENVIRONMENT (${environmentString}) is not provided or is not an accepted value.`);
}

const environmentType: EnvironmentType = environmentString as EnvironmentType;

const feBaseUrl: string = GetRequiredEnvVarOrExit('REACT_APP_FE_BASE_URL');
const beBaseUrl: string = GetRequiredEnvVarOrExit('REACT_APP_BE_BASE_URL');

const nbBaseUrl: string = GetRequiredEnvVarOrExit('REACT_APP_NB_BASE_URL');

const cdnBaseUrl: string = GetRequiredEnvVarOrExit('REACT_APP_CDN_BASE_URL');

export const Environment: IEnvironment = {
  environmentType,

  feBaseUrl,
  beBaseUrl,

  nbBaseUrl,

  cdnBaseUrl,
};