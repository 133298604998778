"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtractPublishable = exports.GetTableNameForPublishableType = exports.PublishableType = exports.NbBuildStatus = exports.NbPublicationStatus = void 0;
const error_1 = require("../error");
var NbPublicationStatus;
(function (NbPublicationStatus) {
    NbPublicationStatus["Unpublished"] = "Unpublished";
    NbPublicationStatus["AwaitingUpdate"] = "AwaitingUpdate";
    NbPublicationStatus["AwaitingDeletion"] = "AwaitingDeletion";
    NbPublicationStatus["PublishedUnlisted"] = "PublishedUnlisted";
    NbPublicationStatus["Published"] = "Published";
})(NbPublicationStatus || (exports.NbPublicationStatus = NbPublicationStatus = {}));
var NbBuildStatus;
(function (NbBuildStatus) {
    NbBuildStatus["Built"] = "Built";
    NbBuildStatus["AwaitingUpdate"] = "AwaitingUpdate";
})(NbBuildStatus || (exports.NbBuildStatus = NbBuildStatus = {}));
var PublishableType;
(function (PublishableType) {
    PublishableType["Discipline"] = "Discipline";
    PublishableType["Festival"] = "Festival";
    PublishableType["Show"] = "Show";
})(PublishableType || (exports.PublishableType = PublishableType = {}));
function GetTableNameForPublishableType(publishableType) {
    switch (publishableType) {
        case PublishableType.Discipline:
            return 'disciplines';
        case PublishableType.Festival:
            return 'festivals';
        case PublishableType.Show:
            return 'shows';
        default:
            throw new error_1.IllegalStateError('Invalid publishable type');
    }
}
exports.GetTableNameForPublishableType = GetTableNameForPublishableType;
function ExtractPublishable(input) {
    const { pageId, publicationStatus, buildStatus, } = input;
    return {
        pageId,
        publicationStatus,
        buildStatus,
    };
}
exports.ExtractPublishable = ExtractPublishable;
