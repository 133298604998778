"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateUpdateNbBasicPage = void 0;
const ModelCleaner_1 = require("./ModelCleaner");
const validator_1 = require("../validator");
function* ValidateUpdateNbBasicPage(updateNbBasicPageInput) {
    const updateNbBasicPage = (0, ModelCleaner_1.CleanModel)(updateNbBasicPageInput);
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'id');
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'slug');
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'name');
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'title');
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'headline');
    yield* (0, validator_1.ValidateRequiredProperty)(updateNbBasicPage, 'excerpt');
    const { id, slug, name, title, headline, excerpt, } = updateNbBasicPage;
    // TODO: add more validators here
    return {
        id,
        slug,
        name,
        title,
        headline,
        excerpt,
    };
}
exports.ValidateUpdateNbBasicPage = ValidateUpdateNbBasicPage;
