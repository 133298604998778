//import { AuthState, AuthStateKey } from "../contexts/AuthContext";
import { Nullable } from "@sfiaf/common";

export const getLocalStorage = <T>(
  key: string,
): Nullable<T> => {
  if(isLocalStorageAvailable()) {
    const saved: string | null = localStorage.getItem(key);
    if(saved == null) {
      return null;
    }
    const initial = JSON.parse(saved);
    return initial;
  } else {
    return null;
  }
}

export const setLocalStorage = <T>( // TODO: should be T extends Json
  key: string,
  value: T,
  setValue?: (value: T) => void,
) => {
  localStorage.setItem(key, JSON.stringify(value));
  if (setValue != null) {
    setValue(value);
  }
}

export const deleteLocalStorage = <T>(
  key: string,
  setValue?: (value: Nullable<T>) => void,
) => {
  localStorage.removeItem(key);
  if (setValue != null) {
    setValue(null);
  }
}

// export const setLocalStorageAuthState = (
//   authState: AuthState,
//   setAuthState: (authState: AuthState) => void,
// ) => {
//   return setLocalStorage(AuthStateKey, authState, setAuthState);
// }

// export const deleteLocalStorageAuthState = (
//   setAuthState: (authState: AuthState | null) => void,
// ) => {
//   return deleteLocalStorage(AuthStateKey, setAuthState);
// }

export const isLocalStorageAvailable = (): boolean => {
  return typeof window !== 'undefined';
}