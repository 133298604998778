import { useState } from "react";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { BaseError, Conditional } from "@sfiaf/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LabelSpinnerButton, SpinnerButton } from "../components/SpinnerButton";
import { StyledModal } from "../components/StyledModal";
import { ErrorLabel } from "../components/ErrorLabel";
import styled from "styled-components";


const Title = styled.h2`
`;

const Subtitle = styled.label`
`;

const ButtonContainer = styled.div`
  width: 40%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


interface DeleteResourceProps<T> {
  resource: T;
  onDeletion: (resource: T) => Promise<void>;
  redirect: string;
}

export function DeleteResource<T>({
  resource,
  onDeletion,
  redirect,
}: DeleteResourceProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      await onDeletion(resource);
      toast('Success');
      navigate(redirect);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
    }

    setIsLoading(false);
  };

  const onDismiss = () => {
    setIsLoading(false);
    setApiError(null);
    setIsModalOpen(false);
  }

  return <>
    <LabelSpinnerButton isLoading={isLoading}
    disabled={false}
    onClick={() => setIsModalOpen(true)}>
      Delete...
    </LabelSpinnerButton>
    <StyledModal
        isOpen={isModalOpen}
        onAfterOpen={() => {}}
        contentLabel="Modal"
      >
        <Title>Are you sure you want to delete this resource?</Title>
        <Subtitle>This action cannot be undone.</Subtitle>
        <ButtonContainer>
          <SpinnerButton disabled={isLoading} onClick={onDismiss} isLoading={false}>Cancel</SpinnerButton>
          <SpinnerButton onClick={onSubmit} isLoading={isLoading}>Delete</SpinnerButton>
        </ButtonContainer>
        <Conditional on={apiError}>
          <ErrorLabel>
            {apiError?.message}
          </ErrorLabel>
      </Conditional>
      </StyledModal>
  </>;
}