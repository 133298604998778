import { useEffect, useState } from 'react';
import { getLocalStorage, isLocalStorageAvailable } from '../utils/localStorage';
import { Nullable } from '@sfiaf/common';

export function useLocalStorage<T>(
  key: string,
  defaultValue: Nullable<T> = null,
): [Nullable<T>, (value: Nullable<T>) => void] {
  // use dynamic getter to retrieve from local storage
  const [value, setValue] = useState<T | null>(() => {
    const value: T | null = getLocalStorage(key);
    if (value != null) {
      return value;
    }
    else {
      return defaultValue;
    }
  });

  // the enclosed func is fired any time the key or value are mutated, i.e. the setValue that is returned will
  //  not be used, and instead the useEffect is the means by which data will be set into local storage
  useEffect(() => {
    if(isLocalStorageAvailable()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};