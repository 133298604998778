"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateOrThrow = void 0;
const error_1 = require("../error");
const GeneratorExecutor_1 = require("./GeneratorExecutor");
function ValidateOrThrow(generator) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { yieldValues, returnValue, } = yield (0, GeneratorExecutor_1.ExecuteGenerator)(generator);
            if (yieldValues.length > 0) {
                throw new error_1.MultiValidationError(yieldValues);
            }
            return returnValue;
        }
        catch (e) {
            if (e instanceof error_1.MultiValidationError) {
                throw e;
            }
            else {
                throw new error_1.InternalError(`Unexpected error during validation: ${e}`);
            }
        }
    });
}
exports.ValidateOrThrow = ValidateOrThrow;
