import { deviceBreakpoints } from '../utils/deviceBreakpoints';
import { Theme } from '../theme/Theme';
import { IfElse, Prefix } from '@sfiaf/common';

import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';


interface SpinnerButtonInternalProps {
  backgroundColor: string;
  textColor: string;
}

type PrefixSpinnerButtonInternalProps = Prefix<SpinnerButtonInternalProps, '$'>;

const SpinnerButtonInternal = styled.button<PrefixSpinnerButtonInternalProps>`
  outline: 0;
  border-radius: 5px;
  border: none;
  background-color: ${props => props.$backgroundColor};
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.$textColor};
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  &:disabled {
    border: none;
    background-color: ${Theme.color.gray};
    cursor: default;
  }
`;

const ButtonText = styled.label`
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;



export interface SpinnerButtonProps extends 
  ButtonHTMLAttributes<HTMLButtonElement>,
  Partial<SpinnerButtonInternalProps>,
  PropsWithChildren {
  isLoading: boolean;
}

export function SpinnerButton({
  isLoading,
  children,
  backgroundColor = Theme.color.orange,
  textColor = Theme.color.white,
  disabled,
  ...rest
}: SpinnerButtonProps) {
  if (isLoading === true) {
    disabled = true;
  }

  return <SpinnerButtonInternal
  $backgroundColor={backgroundColor}
  $textColor={textColor}
  disabled={disabled} 
  {...rest}
  >
    <IfElse on={isLoading}>
      <TailSpin
      visible={true}
      height="16"
      width="16"
      color={Theme.color.white}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      />
      <ButtonText>{children}</ButtonText>
    </IfElse>
  </SpinnerButtonInternal>;
}

export function LabelSpinnerButton({
  isLoading,
  children,
  backgroundColor = 'transparent',
  textColor = Theme.color.orange,
  disabled,
  ...rest
}: SpinnerButtonProps) {
  if (isLoading === true) {
    disabled = true;
  }

  return <SpinnerButtonInternal
  $backgroundColor={backgroundColor}
  $textColor={textColor}
  disabled={disabled} 
  {...rest}
  >
    <IfElse on={isLoading}>
      <TailSpin
      visible={true}
      height="16"
      width="16"
      color={Theme.color.white}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      />
      <ButtonText>{children}</ButtonText>
    </IfElse>
  </SpinnerButtonInternal>;
}