"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserLocationApi = exports.UserLocationApi = void 0;
const BaseApi_1 = require("./BaseApi");
class SharedLocationApi extends BaseApi_1.CredentialedBaseApi {
    getAllLocationsForFestivalYear(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/location/${year}`);
            return result;
        });
    }
    getAllPerformancesWithDurationForLocation(locationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/location/${locationId}/performances`);
            return result;
        });
    }
}
class UserLocationApi extends SharedLocationApi {
    getAllLocations() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/location`);
            return result;
        });
    }
}
exports.UserLocationApi = UserLocationApi;
class SuperuserLocationApi extends SharedLocationApi {
    getAllLocations() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/location`);
            return result;
        });
    }
    createLocation(createLocation) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/location', createLocation);
            return result;
        });
    }
    updateLocation(updateLocation) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/superuser/location', updateLocation);
            return result;
        });
    }
    deleteLocation(deleteLocation) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('POST', '/superuser/location/delete', deleteLocation);
        });
    }
}
exports.SuperuserLocationApi = SuperuserLocationApi;
