"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateSuperuserUpdateUser = exports.ValidateSuperuserCreateUser = exports.ValidateUser = void 0;
const validator_1 = require("../validator");
function* ValidateUser(user) {
    const { id, email: inputEmail, festivalYears, } = user;
    yield* (0, validator_1.ValidateNonnull)(id, 'id');
    const email = yield* (0, validator_1.ValidateEmail)(inputEmail, 'email');
    yield* (0, validator_1.ValidateMinLength)(email, 5, 'email');
    yield* (0, validator_1.ValidateMaxLength)(email, 30, 'email');
    return {
        id,
        email,
        festivalYears,
    };
}
exports.ValidateUser = ValidateUser;
function* ValidateSuperuserCreateUser(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'email');
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'festivalYears');
    const { email: inputEmail, festivalYears, } = input;
    const email = yield* (0, validator_1.ValidateEmail)(inputEmail, 'email');
    yield* (0, validator_1.ValidateMinLength)(email, 5, 'email');
    yield* (0, validator_1.ValidateMaxLength)(email, 30, 'email');
    return {
        email,
        festivalYears,
    };
}
exports.ValidateSuperuserCreateUser = ValidateSuperuserCreateUser;
// export function ValidateUpdateUser(input: UpdateUser): UpdateUser {
//   ValidateRequiredProperty(input, 'id');
//   ValidateRequiredProperty(input, 'email');
//   const {
//     id,
//     email: inputEmail,
//   } = input;
//   const email: string = ValidateEmail(inputEmail);
//   return {
//     id,
//     email,
//   };
// }
function* ValidateSuperuserUpdateUser(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'id');
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'email');
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'festivalYears');
    const { id, email: inputEmail, festivalYears, } = input;
    const email = yield* (0, validator_1.ValidateEmail)(inputEmail, 'email');
    yield* (0, validator_1.ValidateMinLength)(email, 5, 'email');
    yield* (0, validator_1.ValidateMaxLength)(email, 30, 'email');
    return {
        id,
        email,
        festivalYears,
    };
}
exports.ValidateSuperuserUpdateUser = ValidateSuperuserUpdateUser;
