"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateInteger = void 0;
const error_1 = require("../error");
function* ValidateInteger(input, name) {
    if (input == null) {
        yield new error_1.ValidationError(`The value must not be null`, name);
        return;
    }
    if (Number.isInteger(input) !== true) {
        yield new error_1.ValidationError(`The value must be an integer`, name);
        return;
    }
    if (isFinite(input) !== true) {
        yield new error_1.ValidationError(`The value must not be NaN or +/- Infinity`, name);
        return;
    }
}
exports.ValidateInteger = ValidateInteger;
