"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AreSetsEqual = void 0;
function AreSetsEqual(set1, set2) {
    if (set1 === set2)
        return true;
    if (set1.size !== set2.size)
        return false;
    for (const value of set1)
        if (!set2.has(value))
            return false;
    return true;
}
exports.AreSetsEqual = AreSetsEqual;
