import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { BaseError, Conditional, CreateLocation, Festival, Location, ValidateCreateLocation } from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext } from "../contexts/AuthContext";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { SuperuserLocationApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedFieldContainer, ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";
import Select from 'react-select';

// curry. kind of a pain to get the react-select set up to work with react-hook-form (not hard, just need to use a Controller from the latter), so just manually handling the year value for now
function CreateLocationValidatorWrapper(year: number) {
  function *CreateLocationValidator(input: CreateLocation) {
    input.festivalYear = year;
    yield *ValidateCreateLocation(input);
  }

  return CreateLocationValidator;
}

export function LocationCreatePanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals, locations, setLocations, locationsByYear, setLocationsByYear } = useContextOrThrow(FestivalContext);

  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const navigate = useNavigate();

  const festival: Festival = festivals[selectedYearIndex];

  const resolver = useValidatorResolver(CreateLocationValidatorWrapper(festival.year));
  const { register, handleSubmit, formState: { errors } } = useForm<CreateLocation>({
    resolver,
  });

  const onSubmit: SubmitHandler<CreateLocation> = async (createLocation) => {
    setIsLoading(true);

    createLocation.festivalYear = festival.year;

    try {
      const api = apiState!.locationApi as SuperuserLocationApi;
      const resultLocation: Location = await api.createLocation(createLocation);

      locations.set(resultLocation.id, resultLocation);
      setLocations(locations);

      const yearLocation = locationsByYear.get(resultLocation.festivalYear)!;
      yearLocation.push(resultLocation);
      locationsByYear.set(resultLocation.festivalYear, yearLocation);
      setLocationsByYear(locationsByYear);

      setIsLoading(false);
      toast('Success');
      navigate('/location');
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  const selectOptions = festivals.map((festival, index) => ({value: index, label: `${festival.year}`}));
  if (selectOptions.length === 0) {
    selectOptions.push({value: 0, label: `N/A`});
  }
  
  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Create Location</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/location'>&lt; Locations</ColoredLink>
    </AuthenticatedSubheader>
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <label>Year</label>
      <Select
      options={selectOptions}
      value={selectOptions[selectedYearIndex]}
      onChange={(option) => {setSelectedYearIndex(option!.value)}}
      />
      <ValidatedInput type='text' fieldName='name' register={register} errors={errors}/>
      <ValidatedInput type='text' fieldName='address' register={register} errors={errors}/>
      
      <SpinnerButton type='submit' isLoading={isLoading}>
        Create
      </SpinnerButton>
      <Conditional on={apiError}>
        <ErrorLabel>
          {apiError?.message}
        </ErrorLabel>
      </Conditional>
    </FormContainer>
  </>;
}