"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AreArraysSetwiseEqual = void 0;
const AreSetsEqual_1 = require("./AreSetsEqual");
function AreArraysSetwiseEqual(array1, array2) {
    const set1 = new Set(array1);
    const set2 = new Set(array2);
    return (0, AreSetsEqual_1.AreSetsEqual)(set1, set2);
}
exports.AreArraysSetwiseEqual = AreArraysSetwiseEqual;
// TODO: add order-based equality check
