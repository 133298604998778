"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripObjectKeysByValue = void 0;
// given an object, it will recursively strip out all keys which have the given `disallowedValue`.
// NOTE: TOriginalValue should not be an object, otherwise recursion will break
// IMPORTANT NOTE: this *technically* returns TObject but realistically should be a Partial<TObject>.
function StripObjectKeysByValue(input, disallowedValue) {
    const result = {};
    try {
        for (const key in input) {
            const value = input[key];
            if (value !== disallowedValue) {
                result[key] = value;
            }
            else if (value.hasOwnProperty('constructor') &&
                value.constructor === Object) {
                result[key] = StripObjectKeysByValue(value, disallowedValue);
            }
        }
    }
    catch (e) {
        // console.log(e);
        throw e;
    }
    return result;
}
exports.StripObjectKeysByValue = StripObjectKeysByValue;
