import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BaseError, Conditional, User, IfElse, SuperuserUpdateUser, ValidateSuperuserUpdateUser, SuperuserDeleteUser, Show, } from "@sfiaf/common";
import { ErrorLabel } from "../components/ErrorLabel";
import { CenteredContentContainer, FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { SuperuserShowApi, SuperuserUserApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { FormContainer } from "../components/FormContainer";
import { DeleteResource } from "./DeleteResource";
import { Environment } from "../environment/Environment";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";

import Select from 'react-select';
import styled from "styled-components";


const ShowTitle = styled.h3`
  margin-top: 30px;
`;

const ShowListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ShowLink = styled(ColoredLink)`
  // margin-top: 10px;
`



function *UpdateUserValidator(input: SuperuserUpdateUser) {
  yield *ValidateSuperuserUpdateUser(input);
}


interface UpdateUserProps {
  user: User;
}

function UpdateUserForm({user}: UpdateUserProps) {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals } = useContextOrThrow(FestivalContext);

  const [selectedYears, setSelectedYears] = useState<Array<number>>(user.festivalYears);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(UpdateUserValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<SuperuserUpdateUser>({
    resolver,
    defaultValues: user,
  });

  const onSubmit: SubmitHandler<SuperuserUpdateUser> = async (updateUser) => {
    setIsLoading(true);

    updateUser.festivalYears = selectedYears;

    try {
      const api = apiState!.userApi as SuperuserUserApi;
      const resultUser: User = await api.updateUser(updateUser);

      setIsLoading(false);
      toast(`Success`);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  const selectOptions = festivals.map(festival => ({value: festival.year, label: `${festival.year}`}));
  if (selectOptions.length === 0) {
    selectOptions.push({value: 0, label: `N/A`});
  }
  
  const defaultOptions = selectedYears.sort().map(year => ({value: year, label: `${year}`}));

  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <ValidatedInput type='email' fieldName='email' register={register} errors={errors}/>
    <Select
      isMulti
      isClearable={false}
      options={selectOptions}
      value={defaultOptions}
      onChange={(options) => {
        const years = options.map(option => option.value).sort();
        setSelectedYears(years);
      }}
      />
    <SpinnerButton type='submit' isLoading={isLoading}>
      Update
    </SpinnerButton>

    <Conditional on={apiError}>
      <ErrorLabel>
        {apiError?.message}
      </ErrorLabel>
    </Conditional>
  </FormContainer>;
}



export function UserDetailsPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { disciplines } = useContextOrThrow(FestivalContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [shows, setShows] = useState<Array<Show>>(new Array());

  const userIdString: string | undefined = useParams()['*'];

  const userId: number = Number(userIdString);

  useAsyncMountEffect(async () => {
    const api: SuperuserUserApi = apiState!.userApi as SuperuserUserApi;
    const showApi: SuperuserShowApi = apiState!.showApi as SuperuserShowApi;

    try {
      const user: User = await api.getUser(userId);
      setUser(user);

      const shows: Array<Show> = await showApi.getAllShowsForUser(userId);
      const sortedShows = shows.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        else if (a.name < b.name) {
          return -1;
        }
        else {
          return 0;
        }
      });
      setShows(sortedShows);

      setIsLoading(false);
    }
    catch (e) {
      toast(`Failed to fetch artist. Please try again later. (Reason: ${e}.)`);
    }

    setIsLoading(false);
  });



  const onDeletion = async (user: User) => {
    const api = apiState!.userApi as SuperuserUserApi;
    const deleteUser: SuperuserDeleteUser = {
      id: user.id,
    };
    await api.deleteUser(deleteUser);
  };

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Artist Details: {user?.email}</AuthenticatedHeaderTitle>
      </AuthenticatedHeader>
      <AuthenticatedSubheader>
        <ColoredLink to='/artist'>&lt; Users</ColoredLink>
        <DeleteResource resource={user!} onDeletion={onDeletion} redirect='/artist'/>
      </AuthenticatedSubheader>
      <IfElse on={user}>
        <>
          <UpdateUserForm user={user!}/>
          <ShowTitle>Shows:</ShowTitle>
          <ShowListContainer>
            {shows.map(show => {
              const discipline = disciplines.get(show.disciplineId)!;
              return <ShowLink key={show.id} to={`/show/${show.id}`}>{show.name} ({discipline.festivalYear})</ShowLink>
            })}
          </ShowListContainer>
          
        </>
        <>
          <FullHeightCenteredContentContainer>
            <ErrorLabel>Record does not exist</ErrorLabel>
          </FullHeightCenteredContentContainer>
        </>
      </IfElse>
    </>
  </IfElse>
}