"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateEmail = void 0;
const error_1 = require("../error");
const ValidateEmailRegExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// if empty string is returned, that signifies a validation failure (more importantly, look for yielded validationerrors)
function* ValidateEmail(email, fieldName) {
    if (email == null) {
        yield new error_1.ValidationError(`email is required`, 'fieldName');
        return '';
    }
    const resultEmail = email.toLowerCase().trim();
    if (ValidateEmailRegExp.test(String(resultEmail).toLowerCase()) !== true) {
        yield new error_1.ValidationError(`The value must be a valid email format`, fieldName);
    }
    return resultEmail;
}
exports.ValidateEmail = ValidateEmail;
