"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserUserApi = exports.UserApi = exports.UserLoginApi = void 0;
const BaseApi_1 = require("./BaseApi");
class UserLoginApi extends BaseApi_1.BaseApi {
    sendMagicLink(email) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('POST', '/user/send_magic_link', { email });
        });
    }
    login(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/user/login', { token });
            return result;
        });
    }
}
exports.UserLoginApi = UserLoginApi;
class UserApi extends BaseApi_1.CredentialedBaseApi {
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('DELETE', '/user/logout');
        });
    }
    getCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/user/current');
            return result;
        });
    }
}
exports.UserApi = UserApi;
class SuperuserUserApi extends BaseApi_1.CredentialedBaseApi {
    getAllUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/superuser/user');
            return result;
        });
    }
    getUser(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/superuser/user/${id}`);
            return result;
        });
    }
    createUser(superuserCreateUser) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/user', superuserCreateUser);
            return result;
        });
    }
    updateUser(superuserUpdateUser) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/superuser/user', superuserUpdateUser);
            return result;
        });
    }
    deleteUser(superuserDeleteUser) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('POST', '/superuser/user/delete', superuserDeleteUser);
        });
    }
}
exports.SuperuserUserApi = SuperuserUserApi;
