"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuplicateError = void 0;
const BaseError_1 = require("./BaseError");
const DefaultErrorMessage = 'Resource already exists';
class DuplicateError extends BaseError_1.BaseError {
    constructor(message = DefaultErrorMessage) {
        super(message);
        this.statusCode = 409;
    }
}
exports.DuplicateError = DuplicateError;
