"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiError = void 0;
const BaseError_1 = require("./BaseError");
function BuildDefaultErrorMessage(errors) {
    if (errors == null || errors.length === 0) {
        return `An unknown error occurred.`;
    }
    if (errors.length === 1) {
        const error = errors[0];
        return error.message;
    }
    let result = `Multiple errors occurred:`;
    for (const error of errors) {
        result = `${result}\n${error.message}`;
    }
    return result;
}
class MultiError extends BaseError_1.BaseError {
    constructor(errors) {
        const message = BuildDefaultErrorMessage(errors);
        super(message);
        this.statusCode = 400;
        this.data = { errors };
    }
}
exports.MultiError = MultiError;
