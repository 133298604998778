import { RouteWrapper } from './RouteWrapper';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from 'styled-components';
import { AuthContextProvider } from './contexts/AuthContextProvider';

const AppContainer = styled.div`
`;

function App() {
  return (
    <AppContainer>
      <AuthContextProvider>
        <ToastContainer />
        <RouteWrapper />
      </AuthContextProvider>
    </AppContainer>
  );
}

export default App;