"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = void 0;
const BaseError_1 = require("./BaseError");
const DefaultErrorMessage = 'Validation error';
class ValidationError extends BaseError_1.BaseError {
    constructor(message = DefaultErrorMessage, fieldName) {
        super(message);
        this.statusCode = 422;
        this.data = { fieldName };
    }
}
exports.ValidationError = ValidationError;
