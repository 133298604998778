"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateDeleteSuperuser = exports.ValidateUpdateSuperuser = exports.ValidateCreateSuperuser = void 0;
const validator_1 = require("../validator");
function* ValidateCreateSuperuser(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'email');
    const { email: inputEmail, } = input;
    const email = yield* (0, validator_1.ValidateEmail)(inputEmail, 'email');
    yield* (0, validator_1.ValidateMinLength)(email, 5, 'email');
    yield* (0, validator_1.ValidateMaxLength)(email, 30, 'email');
    return {
        email,
    };
}
exports.ValidateCreateSuperuser = ValidateCreateSuperuser;
function* ValidateUpdateSuperuser(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'id');
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'email');
    const { id, email: inputEmail, } = input;
    const email = yield* (0, validator_1.ValidateEmail)(inputEmail, 'email');
    yield* (0, validator_1.ValidateMinLength)(email, 5, 'email');
    yield* (0, validator_1.ValidateMaxLength)(email, 30, 'email');
    return {
        id,
        email,
    };
}
exports.ValidateUpdateSuperuser = ValidateUpdateSuperuser;
function* ValidateDeleteSuperuser(input) {
    yield* (0, validator_1.ValidateRequiredProperty)(input, 'id');
    const { id, } = input;
    return {
        id,
    };
}
exports.ValidateDeleteSuperuser = ValidateDeleteSuperuser;
