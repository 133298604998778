"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateRange = void 0;
const error_1 = require("../error");
// the range is inclusive
function* ValidateRange(input, lower, upper, name) {
    if (input == null) {
        yield new error_1.ValidationError(`The value must not be null`, name);
        return;
    }
    if (input < lower || input > upper) {
        yield new error_1.ValidationError(`The value must be between ${lower} and ${upper} (inclusive)`, name);
    }
}
exports.ValidateRange = ValidateRange;
