"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrimObjectStringValues = void 0;
// via: https://stackoverflow.com/a/63791465
// recursively evaluates each key-value pair in a given object, then for each value which is a string, it will `trim()` it
function TrimObjectStringValues(input) {
    const trimmed = JSON.stringify(input, (key, value) => {
        if (typeof value === 'string') {
            return value.trim();
        }
        return value;
    });
    const result = JSON.parse(trimmed);
    return result;
}
exports.TrimObjectStringValues = TrimObjectStringValues;
