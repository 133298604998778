"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiValidationError = void 0;
const MultiError_1 = require("./MultiError");
const MultiValidationDefaultErrorMessage = 'Validation error';
class MultiValidationError extends MultiError_1.MultiError {
    constructor(errors) {
        super(errors);
        this.statusCode = 422;
    }
}
exports.MultiValidationError = MultiValidationError;
