import {
  AsyncValidationGenerator,
  MultiValidationError,
  ValidateOrThrow,
  ValidationError,
  ValidationGenerator,
} from '@sfiaf/common';

import {
  FieldValues,
  Resolver,
  ResolverOptions,
} from 'react-hook-form';

export type ValidatorFunction<TInput extends FieldValues> = (
  input: TInput,
  _context: any,
  options: ResolverOptions<TInput>
) => ValidationGenerator<void> | AsyncValidationGenerator<void>;

export function useValidatorResolver<T extends FieldValues>(
  validator: ValidatorFunction<T>
): Resolver<T> {
  return useMultiValidatorResolver([validator]);
}

// via https://codesandbox.io/s/react-hook-form-optimized-custom-resolver-2pyrh?file=/src/custom-resolver.ts
export function useMultiValidatorResolver<T extends FieldValues>(
  validators: Array<ValidatorFunction<T>>
): Resolver<T> {
  return async function (input, _context, options) {
    const allErrors: Record<string, ValidationError> = {};

    for (const validator of validators) {
      try {
        await ValidateOrThrow(validator(input, _context, options));
      }
      catch (e) {
        const error: MultiValidationError = (e as MultiValidationError);
        for (const ve of error.data.errors) {
          const name: string = ve.data.fieldName;
          allErrors[name] = ve;
        }
      }
    }

    return {
      values: input,
      errors: allErrors,
    };
  }
}