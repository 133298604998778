"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YieldMap = void 0;
const YieldMutator_1 = require("./YieldMutator");
function* YieldMap(inputs, generator, yieldMutator, returnMutator, nextValueProvider) {
    const results = new Array();
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        function InputYieldMutator(input, inputYieldIndex) {
            if (yieldMutator != null) {
                return yieldMutator(input, i, inputYieldIndex);
            }
            else {
                return input;
            }
        }
        const result = yield* (0, YieldMutator_1.YieldMutateSync)(generator(input), InputYieldMutator, returnMutator, nextValueProvider);
        let finalResult;
        if (returnMutator != null) {
            finalResult = returnMutator(result);
        }
        else {
            finalResult = result;
        }
        results.push(finalResult);
    }
    return results;
}
exports.YieldMap = YieldMap;
