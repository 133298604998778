import styled from "styled-components";
import { Theme } from "../theme/Theme";
import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";

export const ColoredLink = styled(Link)`
  text-decoration-line: underline;
  cursor: pointer;
  color: ${Theme.color.orange};
`;

export const ColoredALink = styled.a`
  text-decoration-line: underline;
  cursor: pointer;
  color: ${Theme.color.orange};
`;