"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserFestivalApi = exports.UserFestivalApi = void 0;
const BaseApi_1 = require("./BaseApi");
class SharedFestivalApi extends BaseApi_1.CredentialedBaseApi {
    getFestival(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/festival/${year}`);
            return result;
        });
    }
}
class UserFestivalApi extends SharedFestivalApi {
    getAllFestivalsForCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/festival');
            return result;
        });
    }
}
exports.UserFestivalApi = UserFestivalApi;
class SuperuserFestivalApi extends SharedFestivalApi {
    getAllFestivals() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/superuser/festival');
            return result;
        });
    }
    createFestival(createFestival) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/festival', createFestival);
            return result;
        });
    }
    updateFestival(updateFestival) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/superuser/festival', updateFestival);
            return result;
        });
    }
    deleteFestival(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', `/superuser/festival/delete`, { year });
            return result;
        });
    }
}
exports.SuperuserFestivalApi = SuperuserFestivalApi;
