"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperuserDisciplineApi = exports.UserDisciplineApi = void 0;
const BaseApi_1 = require("./BaseApi");
class SharedDisciplineApi extends BaseApi_1.CredentialedBaseApi {
    getAllDisciplinesForYear(year) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', `/discipline/${year}`);
            return result;
        });
    }
}
class UserDisciplineApi extends SharedDisciplineApi {
    getAllDisciplines() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/discipline');
            return result;
        });
    }
}
exports.UserDisciplineApi = UserDisciplineApi;
class SuperuserDisciplineApi extends SharedDisciplineApi {
    getAllDisciplines() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('GET', '/superuser/discipline');
            return result;
        });
    }
    createDiscipline(createDiscipline) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('POST', '/superuser/discipline', createDiscipline);
            return result;
        });
    }
    updateDiscipline(updateDiscipline) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.submitRequest('PUT', '/superuser/discipline', updateDiscipline);
            return result;
        });
    }
    deleteDiscipline(deleteDiscipline) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.submitRequest('POST', '/superuser/discipline/delete', deleteDiscipline);
        });
    }
}
exports.SuperuserDisciplineApi = SuperuserDisciplineApi;
