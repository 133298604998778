import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BaseError, Conditional, Location, IfElse, UpdateLocation, ValidateUpdateLocation, DeleteLocation, } from "@sfiaf/common";
import { ErrorLabel } from "../components/ErrorLabel";
import { CenteredContentContainer, FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { SuperuserLocationApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { FormContainer } from "../components/FormContainer";
import { DeleteResource } from "./DeleteResource";
import { Environment } from "../environment/Environment";



function *UpdateLocationValidator(input: UpdateLocation) {
  yield *ValidateUpdateLocation(input);
}


interface UpdateLocationProps {
  location: Location;
}

function UpdateLocationForm({location}: UpdateLocationProps) {
  const { apiState } = useContextOrThrow(AuthContext);
  const { locations, setLocations, locationsByYear, setLocationsByYear } = useContextOrThrow(FestivalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(UpdateLocationValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<UpdateLocation>({
    resolver,
    defaultValues: location,
  });

  const onSubmit: SubmitHandler<UpdateLocation> = async (updateLocation) => {
    setIsLoading(true);

    try {
      const api = apiState!.locationApi as SuperuserLocationApi;
      const resultLocation: Location = await api.updateLocation(updateLocation);

      locations.set(resultLocation.id, resultLocation);
      setLocations(locations);

      const locationsForYear = locationsByYear.get(location.festivalYear)!;
      const updatedLocationsForYear = locationsForYear.filter(l => l.id !== location.id);
      updatedLocationsForYear.push(resultLocation);

      locationsByYear.set(location.festivalYear, updatedLocationsForYear);
      setLocationsByYear(locationsByYear);

      setIsLoading(false);
      toast(`Success`);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <ValidatedInput type='text' fieldName='name' register={register} errors={errors}/>
    <ValidatedInput type='text' fieldName='address' register={register} errors={errors}/>
    <IfElse on={location.isOnline}>
      <>
        <CenteredContentContainer>
          <p>{`(Online events cannot be edited)`}</p>
        </CenteredContentContainer>
      </>
      <>
        <SpinnerButton type='submit' isLoading={isLoading}>
          Update
        </SpinnerButton>
      </>
    </IfElse>
    <Conditional on={apiError}>
      <ErrorLabel>
        {apiError?.message}
      </ErrorLabel>
    </Conditional>
  </FormContainer>;
}



export function LocationDetailsPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { locations, setLocations, locationsByYear, setLocationsByYear } = useContextOrThrow(FestivalContext);

  const locationIdString: string | undefined = useParams()['*'];

  const locationId: number = Number(locationIdString);

  const location: Location = locations.get(locationId)!;


  const onDeletion = async (location: Location) => {
    const api = apiState!.locationApi as SuperuserLocationApi;
    const deleteLocation: DeleteLocation = {
      id: location.id,
    };
    await api.deleteLocation(deleteLocation);

    locations.delete(location.id);
    setLocations(locations);

    const locationsForYear = locationsByYear.get(location.festivalYear)!;
    const updatedLocationsForYear = locationsForYear.filter(l => l.id !== location.id);
    locationsByYear.set(location.festivalYear, updatedLocationsForYear);
    setLocationsByYear(locationsByYear);
  };

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Location Details: {location?.name} ({location?.festivalYear})</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/location'>&lt; Locations</ColoredLink>
      <Conditional on={location.isOnline !== true}>
        <DeleteResource resource={location} onDeletion={onDeletion} redirect='/location'/>
      </Conditional>
    </AuthenticatedSubheader>
    <IfElse on={location}>
      <>
        <UpdateLocationForm location={location}/>
      </>
      <>
        <FullHeightCenteredContentContainer>
          <ErrorLabel>Record does not exist</ErrorLabel>
        </FullHeightCenteredContentContainer>
      </>
    </IfElse>
  </>
}