import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BaseError, BuildDisciplineSlug, Conditional, DeleteDiscipline, Discipline, IfElse, UpdateDiscipline, ValidateUpdateDiscipline } from "@sfiaf/common";
import { ErrorLabel } from "../components/ErrorLabel";
import { CenteredContentContainer, FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { SuperuserDisciplineApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { FormContainer } from "../components/FormContainer";
import { DeleteResource } from "./DeleteResource";
import { Environment } from "../environment/Environment";
import styled from "styled-components";
import { toBase64 } from "../utils/toBase64";


const Image = styled.img`
  object-fit: cover;
  aspect-ratio: 3/2;
  max-width: 600px;
`;

const ImageSection = styled(CenteredContentContainer)`
  margin-top: 20px;
  margin-bottom: 20px;
`



function *UpdateDisciplineValidator(input: UpdateDiscipline) {
  yield *ValidateUpdateDiscipline(input);
}


interface UpdateDisciplineProps {
  discipline: Discipline;
}

function UpdateDisciplineForm({discipline}: UpdateDisciplineProps) {
  const { apiState } = useContextOrThrow(AuthContext);
  const {
    disciplines,
    setDisciplines,
    disciplinesByYear,
    setDisciplinesByYear,
  } = useContextOrThrow(FestivalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(UpdateDisciplineValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<UpdateDiscipline>({
    resolver,
    defaultValues: discipline,
  });

  const onSubmit: SubmitHandler<UpdateDiscipline> = async (updateDiscipline) => {
    setIsLoading(true);

    try {
      if (imageFile != null) {
        updateDiscipline.imageContentType = imageFile.type;
        const imageBase64Full: string = (await toBase64(imageFile)) as string;
        const imageBase64: string = (imageBase64Full as string).split('base64,')[1];
        updateDiscipline.imageData = imageBase64;
      }

      const api = apiState!.disciplineApi as SuperuserDisciplineApi;
      const resultDiscipline: Discipline = await api.updateDiscipline(updateDiscipline);

      disciplines.set(resultDiscipline.id, resultDiscipline);
      setDisciplines(disciplines);

      const disciplinesForYear: Array<Discipline> = disciplinesByYear.get(resultDiscipline.festivalYear)!
        .filter(d => d.id !== resultDiscipline.id);
      disciplinesForYear.push(resultDiscipline);
      const sortedDisciplines: Array<Discipline> = disciplinesForYear.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        else if (a.name < b.name) {
          return -1;
        }
        else {
          return 0;
        }
      });
      
      disciplinesByYear.set(resultDiscipline.festivalYear, sortedDisciplines);
      setDisciplinesByYear(disciplinesByYear);

      setIsLoading(false);
      toast(`Success`);
      
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  let imageSource: string;
  if (imageSrc != null) {
    imageSource = imageSrc;
  }
  else {
    imageSource = `${Environment.cdnBaseUrl}/${discipline.imageKey}`;
  }

  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <ValidatedInput type='text' fieldName='name' register={register} errors={errors}/>

    <ImageSection>
      <Image src={imageSource}/>
      <input type="file" onChange={(e) => {
        if (e.target.files && e.target.files[0]) {
          const file: File = e.target.files[0];
          setImageFile(file);
          setImageSrc(URL.createObjectURL(file));            
        }
      }}></input>
    </ImageSection>

    <SpinnerButton type='submit' isLoading={isLoading}>
      Update
    </SpinnerButton>
    <Conditional on={apiError}>
      <ErrorLabel>
        {apiError?.message}
      </ErrorLabel>
    </Conditional>
  </FormContainer>;
}



export function DisciplineDetailsPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const {
    disciplines,
    setDisciplines,
    disciplinesByYear,
    setDisciplinesByYear,
  } = useContextOrThrow(FestivalContext);

  const idString: string | undefined = useParams()['*'];

  const id: number = Number(idString);

  const discipline: Discipline = disciplines.get(id)!;

  const onDeletion = async (discipline: Discipline) => {
    const api = apiState!.disciplineApi as SuperuserDisciplineApi;
    const deleteDiscipline: DeleteDiscipline = {
      id: discipline.id,
      // TODO: set up discipline migration
    };
    await api.deleteDiscipline(deleteDiscipline);


    disciplines.delete(discipline.id);
    setDisciplines(disciplines);

    const disciplinesForYear = disciplinesByYear.get(discipline.festivalYear)!;
    const updatedDisciplinesForYear = disciplinesForYear.filter(d => d.id !== discipline.id);
    disciplinesByYear.set(discipline.festivalYear, updatedDisciplinesForYear);
    setDisciplinesByYear(disciplinesByYear);
  };

  const slug: string = BuildDisciplineSlug(discipline.festivalYear, discipline.name);
  const nbUrl: string = `${Environment.nbBaseUrl}/${slug}`;

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Discipline Details: {discipline?.name} ({discipline?.festivalYear})</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/discipline'>&lt; Disciplines</ColoredLink>
      <ColoredLink to={nbUrl} target="_blank">View in NB</ColoredLink>
      <DeleteResource resource={discipline} onDeletion={onDeletion} redirect='/discipline'/>
    </AuthenticatedSubheader>
    <IfElse on={discipline}>
      <>
        <UpdateDisciplineForm discipline={discipline}/>
      </>
      <>
        <FullHeightCenteredContentContainer>
          <ErrorLabel>Record does not exist</ErrorLabel>
        </FullHeightCenteredContentContainer>
      </>
    </IfElse>
  </>
}