import { useContextOrThrow } from "../utils/useContextOrThrow";
import { useState } from "react";
import { IfElse, Superuser, } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AuthContext, SuperuserApiState } from "../contexts/AuthContext";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { SuperuserApi, SuperuserUserApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";
import styled from "styled-components";

interface EnrichedSuperuser extends Superuser {
  detailUrl: string;
}

export function SuperuserListPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [superusers, setSuperusers] = useState<Array<EnrichedSuperuser>>(new Array());

  useAsyncMountEffect(async () => {
    const api: SuperuserApi = (apiState as SuperuserApiState)!.superuserApi;

    try {
      const superusers: Array<Superuser> = await api.getAllSuperusers();
      const enrichedSuperusers: Array<EnrichedSuperuser> = superusers.map(user => {
        const detailUrl: string = `/admin/${user.id}`;

        return {
          ...user,
          detailUrl,
        };
      });

      setSuperusers(enrichedSuperusers);
    }
    catch (e) {
      toast(`Failed to fetch admins. Please try again later. (Reason: ${e}.)`);
    }

    setIsLoading(false);
  });

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedSuperuser>>>([
    {field: 'email', sort: 'asc'},
    {field: 'id'},
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Admins</AuthenticatedHeaderTitle>
        <ColoredLink to='/admin/create'>Create...</ColoredLink>
      </AuthenticatedHeader>
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
          <AgGridReact
          rowData={superusers}
          columnDefs={colDefs}
          rowSelection="single"
          defaultColDef={{flex: 1}}
          />
      </div>
    </>
  </IfElse>
}