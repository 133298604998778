import { PropsWithChildren } from "react";
import { useAuthStateLocalStorage } from "../hooks/useAuthStateLocalStorage";
import {
  AuthState,
  AuthContext,
  UserApiState,
  SuperuserApiState,
  BuildSuperuserApiState,
  BuildUserApiState,
  AuthContextState,
} from "./AuthContext";

import {
  BaseError,
  SuperuserOAuthAccessToken,
  UserOAuthAccessToken,
  UserType,
} from '@sfiaf/common';

import { useState } from 'react';
import { useNullableState } from "../utils/useNullableState";
import { useMountEffect } from "../utils/useMountEffect";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { toast } from "react-toastify";

export interface AuthContextProviderProps extends PropsWithChildren {
}

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [authState, setAuthStateInternal] = useAuthStateLocalStorage();

  const [superuserApiState, setSuperuserApiState] = useNullableState<SuperuserApiState>();
  const [userApiState, setUserApiState] = useNullableState<UserApiState>();

  let apiState: SuperuserApiState | UserApiState | null;

  if (authState != null) {
    if (authState.userType === UserType.Superuser) {
      apiState = superuserApiState;
    }
    else {
      apiState = userApiState;
    }
  }
  else {
    apiState = null;
  }

  const apiErrorHandler = (e: any) => {
    if (e instanceof BaseError) {
      const err: BaseError = e as BaseError;
      if (err.name === 'InvalidCredentialsError') {
        toast(`Your session has expired. Please log in again to proceed.`);
        setAuthState(null);
      }
    }
  };

  const setApiStateFromAuthState = (authState: AuthState | null) => {
    if (authState != null) {
      if (authState.userType === UserType.Superuser) {
        const apiState: SuperuserApiState = BuildSuperuserApiState(authState.accessToken.token, apiErrorHandler);
        setSuperuserApiState(apiState);
        setUserApiState(null);
      }
      else {
        const apiState: UserApiState = BuildUserApiState(authState.accessToken.token, apiErrorHandler);
        setUserApiState(apiState);
        setSuperuserApiState(null);
      }
    }
  }

  const setAuthState = (authState: AuthState | null) => {
    setAuthStateInternal(authState);
    setApiStateFromAuthState(authState);
  };

  // if screen is refreshed, then we will load the authState from storage, but we need to rebuild the api state, so try it on mount
  useMountEffect(() => {
    setApiStateFromAuthState(authState);
  })

  const value: AuthContextState = {
    authState: (authState as AuthState),
    setAuthState,
    apiState,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}