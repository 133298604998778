"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateUrl = void 0;
const error_1 = require("../error");
const tidy_url_1 = require("tidy-url");
// if empty string is returned, that signifies a validation failure (more importantly, look for yielded validationerrors)
function* ValidateUrl(input, name) {
    if (input == null) {
        yield new error_1.ValidationError(`The input must not be null`, name);
        return '';
    }
    // marked as deprecated, but `validateURL` does not appear to be exposed (not exported from the library's utils folder)
    try {
        if (tidy_url_1.TidyURL.validate(input) !== true) {
            yield new error_1.ValidationError(`The provided string is not a valid url. Make sure to include the protocol (e.g. 'https://') at the beginning.`, name);
            return '';
        }
    }
    catch (e) { // apparently it throws?
        yield new error_1.ValidationError(`The provided string is not a valid url. Make sure to include the protocol (e.g. 'https://') at the beginning.`, name);
        return '';
    }
    const cleanedData = tidy_url_1.TidyURL.clean(input);
    const cleanedUrl = cleanedData.url;
    return cleanedUrl;
}
exports.ValidateUrl = ValidateUrl;
