import { useParams } from "react-router-dom";
import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BaseError, BuildFestivalSlug, Conditional, Festival, IfElse, UpdateFestival, ValidateUpdateFestival, ValidationError } from "@sfiaf/common";
import { ErrorLabel } from "../components/ErrorLabel";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { SuperuserFestivalApi } from "@sfiaf/api";
import { toast } from "react-toastify";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { FormContainer } from "../components/FormContainer";
import { DeleteResource } from "./DeleteResource";
import { Environment } from "../environment/Environment";



function *UpdateFestivalValidator(input: UpdateFestival) {
  yield *ValidateUpdateFestival(input);
}


interface UpdateFestivalProps {
  festival: Festival;
}

function UpdateFestivalForm({festival}: UpdateFestivalProps) {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals, setFestivals } = useContextOrThrow(FestivalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(UpdateFestivalValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<UpdateFestival>({
    resolver,
    defaultValues: festival,
  });

  const onSubmit: SubmitHandler<UpdateFestival> = async (updateFestival) => {
    setIsLoading(true);

    try {
      const api = apiState!.festivalApi as SuperuserFestivalApi;
      const resultFestival: Festival = await api.updateFestival(updateFestival);

      const oldYear: number = festival.year;
      const remainingFestivals: Array<Festival> = festivals.filter(f => f.year !== oldYear);
      remainingFestivals.push(resultFestival);
      const newFestivals = remainingFestivals.sort((a, b) => a.year - b.year);
      setFestivals(newFestivals);
      setIsLoading(false);
      toast(`Success`);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };

  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <ValidatedInput type='date' fieldName='startDate' register={register} errors={errors}/>
    <ValidatedInput type='date' fieldName='endDate' register={register} errors={errors}/>
    <ValidatedInput type='checkbox' fieldName='editable' register={register} errors={errors}/>
    <SpinnerButton type='submit' isLoading={isLoading}>
      Update
    </SpinnerButton>
    <Conditional on={apiError}>
      <ErrorLabel>
        {apiError?.message}
      </ErrorLabel>
    </Conditional>
  </FormContainer>;
}



export function FestivalDetailsPanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const { festivals, setFestivals } = useContextOrThrow(FestivalContext);

  const yearString: string | undefined = useParams()['*'];

  const year: number = Number(yearString);

  const festivalResult: Array<Festival> = festivals.filter(festival => festival.year === year);
  const festival: Festival | undefined = festivalResult[0];

  const onDeletion = async (festival: Festival) => {
    const api = apiState!.festivalApi as SuperuserFestivalApi;
    await api.deleteFestival(festival.year);
    const updatedFestivals = festivals.filter(f => f.year !== year);
    setFestivals(updatedFestivals);
  };

  const slug: string = BuildFestivalSlug(festival.year);
  const nbUrl: string = `${Environment.nbBaseUrl}/${slug}`;

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Festival Details: {year}</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/festival'>&lt; Festivals</ColoredLink>
      <ColoredLink to={nbUrl} target="_blank">View in NB</ColoredLink>
      <DeleteResource resource={festival} onDeletion={onDeletion} redirect='/festival'/>
    </AuthenticatedSubheader>
    <IfElse on={festival}>
      <>
        <UpdateFestivalForm festival={festival}/>
      </>
      <>
        <FullHeightCenteredContentContainer>
          <ErrorLabel>Record does not exist</ErrorLabel>
        </FullHeightCenteredContentContainer>
      </>
    </IfElse>
  </>
}