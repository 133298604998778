import { Country } from "@sfiaf/common";
import { createContext } from "react";

export interface CountryState {
  countries: Array<Country>;
  setCountries: (countries: Array<Country>) => void;
}



export const CountryContext = createContext<CountryState | null>(null);