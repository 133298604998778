"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalError = void 0;
const BaseError_1 = require("./BaseError");
const DefaultErrorMessage = 'An internal error has occurred. Please try again.';
class InternalError extends BaseError_1.BaseError {
    constructor(message = DefaultErrorMessage) {
        super(message);
        this.statusCode = 500;
    }
}
exports.InternalError = InternalError;
