import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { BuildFestivalSlug, Festival } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { Environment } from "../environment/Environment";

interface EnrichedFestival extends Festival {
  nbUrl: string;
  detailUrl: string;
}

export function FestivalListPanel() {
  const { festivals } = useContextOrThrow(FestivalContext);

  const enrichedFestivals: Array<EnrichedFestival> = festivals.map(festival => {
    const slug: string = BuildFestivalSlug(festival.year);
    const nbUrl: string = `${Environment.nbBaseUrl}/${slug}`;
    const detailUrl: string = `/festival/${festival.year}`;
    return {
      ...festival,
      nbUrl,
      detailUrl,
    };
  });

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedFestival>>>([
    {field: 'year', sort: 'asc'},
    {field: 'startDate'},
    {field: 'endDate'},
    {field: 'editable'},
    {
      field: 'nbUrl',
      headerName: 'NB',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value} target="_blank">View</ColoredLink>;
      },
    },
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Festivals</AuthenticatedHeaderTitle>
      <ColoredLink to='/festival/create'>Create...</ColoredLink>
    </AuthenticatedHeader>
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: 500 }} // the Data Grid will fill the size of the parent container
    >
        <AgGridReact
        rowData={enrichedFestivals}
        columnDefs={colDefs}
        rowSelection="single"
        defaultColDef={{flex: 1}}
        />
    </div>
  </>
}