import { BuildShowSlug, Festival, IfElse, Show, UserType } from "@sfiaf/common";
import { useState } from "react";
import { FullHeightCenteredContentContainer } from "../components/CenteredContentContainer";
import { TailSpin } from "react-loader-spinner";
import { Theme } from "../theme/Theme";
import { AuthenticatedHeader, AuthenticatedHeaderTitle } from "../components/AuthenticatedHeader";

import { useContextOrThrow } from "../utils/useContextOrThrow";
import { FestivalContext } from "../contexts/FestivalContext";
import { BuildDisciplineSlug, Discipline, Location } from "@sfiaf/common";
import { ColoredLink } from "../components/ColoredLink";

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { Environment } from "../environment/Environment";
import { useAsyncMountEffect } from "../utils/useMountEffect";
import { AuthContext } from "../contexts/AuthContext";
import { SuperuserShowApi, UserShowApi } from "@sfiaf/api";

import Select from 'react-select';
import { toast } from "react-toastify";

interface EnrichedShow extends Show {
  nbUrl: string;
  detailUrl: string;
}

export function ShowListPanel() {
  const { authState, apiState } = useContextOrThrow(AuthContext);
  const { festivals, disciplines, locations } = useContextOrThrow(FestivalContext);

  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shows, setShows] = useState<Array<EnrichedShow>>(new Array());

  const enrichAndSetShows = (shows: Array<Show>) => {
    const enrichedShows: Array<EnrichedShow> = shows.map(show => {
      const discipline: Discipline | undefined = disciplines.get(show.disciplineId);
      if (discipline == null) { // shouldn't happen, but just in case
        toast(`Show with id=${show.id} references non-existent discipline with id=${show.disciplineId}`);
      }
      const slug: string = BuildShowSlug(discipline!.festivalYear, show.name);
      const nbUrl: string = `${Environment.nbBaseUrl}/${slug}`;
      const detailUrl: string = `/show/${show.id}`;
      
      return {
        ...show,
        nbUrl,
        detailUrl,
      };
    });

    setShows(enrichedShows);
  };

  const fetchShows = async(index: number) => {
    setIsLoading(true);
    setSelectedYearIndex(index);

    const festival: Festival | undefined = festivals[index];
    if (festival == null) {
      toast(`Festival at index=${index} does not exist.`);
      setIsLoading(false);
      return;
    }

    const festivalYear: number = festival.year;

    try {
      if (authState!.userType === UserType.Superuser) {
        const api: SuperuserShowApi = apiState!.showApi as SuperuserShowApi;
        const shows: Array<Show> = await api.getAllShowsForFestivalYear(festivalYear);
        enrichAndSetShows(shows);
        setIsLoading(false);
      }
      else {
        const api: UserShowApi = apiState!.showApi as UserShowApi;
        const shows: Array<Show> = await api.getAllShowsForCurrentUserAndFestivalYear(festivalYear);
        enrichAndSetShows(shows);
        setIsLoading(false);
      }
    }
    catch (e) {
      toast(`Error occurred. Please try again later. (Details: ${e})`);
    } 
  };

  useAsyncMountEffect(async () => {
    if (festivals.length === 0) {
      toast(`No festivals found.`);
      setIsLoading(false);
      return;
    }
    
    await fetchShows(0);
  });

  const [colDefs, setColDefs] = useState<Array<ColDef<EnrichedShow>>>([
    { field: 'artist', sort: 'asc' },
    {
      field: 'name',
      headerName: 'Show Name',
    },
    {field: 'id'},
    {
      field: 'disciplineId',
      headerName: 'Discipline',
      cellRenderer: (params: any) => {
        const discipline: Discipline | undefined = disciplines.get(params.value);
        if (discipline == null) {
          return <span>({params.value})</span>;
        }

        if (authState!.userType === UserType.Superuser) {
          return <ColoredLink to={`/discipline/${params.value}`}>{discipline.name}</ColoredLink>;
        }
        else {
          return <span>{discipline.name}</span>;
        }
      },
    },
    {
      field: 'locationId',
      headerName: 'Location',
      cellRenderer: (params: any) => {
        const location: Location | undefined = locations.get(params.value);
        if (location == null) {
          return <span>({params.value})</span>;
        }

        if (authState!.userType === UserType.Superuser) {
          return <ColoredLink to={`/location/${params.value}`}>{location.name}</ColoredLink>;
        }
        else {
          return <span>{location.name}</span>;
        }
      },
    },
    {
      field: 'nbUrl',
      headerName: 'NB',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value} target="_blank">View</ColoredLink>;
      },
    },
    {
      field: 'detailUrl',
      headerName: 'Details',
      cellRenderer: (params: any) => {
        return <ColoredLink to={params.value}>Details</ColoredLink>;
      },
    },
  ]);

  const selectOptions = festivals.map((festival, index) => ({value: index, label: `${festival.year}`}));
  if (selectOptions.length === 0) {
    selectOptions.push({value: 0, label: `N/A`});
  }

  return <IfElse on={isLoading}>
    <>
      <FullHeightCenteredContentContainer>
        <TailSpin
        visible={true}
        height="50"
        width="50"
        color={Theme.color.orange}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        />
      </FullHeightCenteredContentContainer>
    </>
    <>
      <AuthenticatedHeader>
        <AuthenticatedHeaderTitle>Shows</AuthenticatedHeaderTitle>
        <ColoredLink to='/show/create'>Create...</ColoredLink>
      </AuthenticatedHeader>
      <Select
      options={selectOptions}
      value={selectOptions[selectedYearIndex]}
      onChange={(option) => {fetchShows(option!.value)}}
      />
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
          <AgGridReact
          rowData={shows}
          columnDefs={colDefs}
          rowSelection="single"
          defaultColDef={{flex: 1}}
          />
      </div>
    </>
  </IfElse>
}