import { Environment } from '../environment/Environment';
import { SpinnerButton } from '../components/SpinnerButton';
import { useValidatorResolver } from '../utils/useValidationResolver';
import { ValidatedInput, ValidatedSelect } from '../components/ValidatedField';
import { Theme } from '../theme/Theme';

import {
  BaseError,
  Conditional,
  IfElse,
  Sleep,
  UserType,
  ValidateEmail,
} from '@sfiaf/common';
import {
  UserLoginApi,
  SuperuserLoginApi,
} from '@sfiaf/api';

import styled from 'styled-components';
import { useForm, SubmitHandler, FieldValues, } from "react-hook-form";
import { PropsWithChildren, useState } from 'react';
import { FullHeightCenteredContentContainer } from '../components/CenteredContentContainer';
import { ErrorLabel } from '../components/ErrorLabel';
import { CenteredDisplayLabel } from '../components/CenteredDisplayLabel';
import { backOff } from 'exponential-backoff';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

interface MagicLinkInput {
  email: string;
  userType: UserType;
}

function *LoginScreenValidator(input: MagicLinkInput) {
  yield *ValidateEmail(input.email, 'email');
}



export function LoginScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [sentEmailAddress, setSentEmailAddress] = useState<string | null>(null);
  const [apiError, setApiError] = useState<BaseError | null>(null);

  const resolver = useValidatorResolver(LoginScreenValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<MagicLinkInput>({ resolver });

  const onSubmit: SubmitHandler<MagicLinkInput> = async (data) => {
    setIsLoading(true);

    const {
      email,
      userType,
    } = data;

    try {
      if (userType === UserType.Superuser) {
        const api = new SuperuserLoginApi(Environment.beBaseUrl);
        await api.sendMagicLink(email);
      }
      else {
        const api = new UserLoginApi(Environment.beBaseUrl);
        await api.sendMagicLink(email);
      }

      setSentEmailAddress(email);
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setCanSubmit(false);
      setIsLoading(false);
    }
  };

  return <FullHeightCenteredContentContainer>
    <h1>SFIAF Portal Login</h1>
    <IfElse on={sentEmailAddress}>
      <>
        <CenteredDisplayLabel>Magic link sent to <strong>{sentEmailAddress}</strong>.</CenteredDisplayLabel>
        <CenteredDisplayLabel>Please click on the link sent to your email to proceed.</CenteredDisplayLabel>
      </>
      <>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <ValidatedInput placeholder='your@email.com' fieldName='email' register={register} errors={errors}/>
          <ValidatedSelect fieldName='userType' register={register} errors={errors}>
            <option value={UserType.User}>Artist</option>
            <option value={UserType.Superuser}>Admin</option>
          </ValidatedSelect>
          <SpinnerButton type='submit'
          isLoading={isLoading}
          >
            Send Magic Link
          </SpinnerButton>
          <Conditional on={apiError}>
            <ErrorLabel>
              {apiError?.message}
            </ErrorLabel>
          </Conditional>
        </FormContainer>
      </>
    </IfElse>
    
  </FullHeightCenteredContentContainer>;
}