import { Discipline, Festival, Location } from "@sfiaf/common";
import { createContext } from "react";

export interface FestivalState {
  festivals: Array<Festival>;
  setFestivals: (festivals: Array<Festival>) => void;
  disciplinesByYear: Map<number, Array<Discipline>>;  // year -> Array<Discipline>
  setDisciplinesByYear: (disciplines: Map<number, Array<Discipline>>) => void;
  disciplines: Map<number, Discipline>;  // discipline.year -> Discipline
  setDisciplines: (disciplines: Map<number, Discipline>) => void;
  locations: Map<number, Location>; // location.id -> Location
  setLocations: (locations: Map<number, Location>) => void;
  locationsByYear: Map<number, Array<Location>>;  // year -> Array<Location>
  setLocationsByYear: (locations: Map<number, Array<Location>>) => void;
}

export const FestivalContext = createContext<FestivalState | null>(null);