import { AuthenticatedPanel, SuperuserAuthenticatedPanel } from '../panels/AuthenticatedPanel';
import { useContextOrThrow } from '../utils/useContextOrThrow';
import { AuthContext, SuperuserApiState, UserApiState } from '../contexts/AuthContext';
import { useAsyncMountEffect, } from '../utils/useMountEffect';

import { LabelSpinnerButton } from '../components/SpinnerButton';
import { FestivalContextProvider } from '../contexts/FestivalContextProvider';
import { CountryContextProvider } from '../contexts/CountryContextProvider';
import { CountryContext } from '../contexts/CountryContext';
import { FestivalContext } from '../contexts/FestivalContext';

import { BaseError, Discipline, Festival, IfElse, Location, UserType } from '@sfiaf/common';
import { SuperuserApi, SuperuserFestivalApi, UserApi, UserFestivalApi } from '@sfiaf/api';

import styled from 'styled-components';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { backOff } from "exponential-backoff";
import { toast } from "react-toastify";
import { FullHeightCenteredContentContainer } from '../components/CenteredContentContainer';
import { TailSpin } from 'react-loader-spinner';
import { Theme } from '../theme/Theme';
import { useAsyncEffect } from '../utils/useAsyncEffect';

import { ShowDetailsPanel } from '../panels/ShowDetailsPanel';
import { ShowListPanel } from '../panels/ShowListPanel';
import { DisciplineDetailsPanel } from '../panels/DisciplineDetailsPanel';
import { DisciplineListPanel } from '../panels/DisciplineListPanel';
import { FestivalDetailsPanel } from '../panels/FestivalDetailsPanel';
import { FestivalListPanel } from '../panels/FestivalListPanel';
import { LocationDetailsPanel } from '../panels/LocationDetailsPanel';
import { LocationListPanel } from '../panels/LocationListPanel';
import { SuperuserDetailsPanel } from '../panels/SuperuserDetailsPanel';
import { SuperuserListPanel } from '../panels/SuperuserListPanel';
import { UserDetailsPanel } from '../panels/UserDetailsPanel';
import { UserListPanel } from '../panels/UserListPanel';
import { DisciplineCreatePanel } from '../panels/DisciplineCreatePanel';
import { FestivalCreatePanel } from '../panels/FestivalCreatePanel';
import { LocationCreatePanel } from '../panels/LocationCreatePanel';
import { SuperuserCreatePanel } from '../panels/SuperuserCreatePanel';
import { UserCreatePanel } from '../panels/UserCreatePanel';
import { ShowCreatePanel } from '../panels/ShowCreatePanel';


const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  background-color: #efefef;
`;

const CenteredTitle = styled.h2`
  text-align: center;
`;

const CenteredSubtitle = styled.p`
  text-align: center;
  font-weight: bold;
`;

const StyledMenu = styled(Menu)`
  width: 100%;
  position: relative;
  height: 100vh;
`;

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const AuthPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  overflow: auto;
  max-height: 100vh;
`;


const CenteredEmail = styled.label`
  font-size: 9pt;
  text-align: center;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledLogoutButton = styled(LabelSpinnerButton)`
  
`;


function SidebarFooter() {
  const { authState, setAuthState, apiState } = useContextOrThrow(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  
  const onSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      let api: SuperuserApi | UserApi;
      if (authState!.userType === UserType.Superuser) {
        api = (apiState as SuperuserApiState)!.superuserApi;
      }
      else {
        api = (apiState as UserApiState)!.userApi;
      }

      await api.logout();
    }
    catch (e) {
      console.log(`Error when logging out: ${e}`);
    }

    setAuthState(null);
  };

  return <FooterContainer>
    <CenteredEmail>{authState?.currentUser.email}</CenteredEmail>
    <StyledLogoutButton
    isLoading={isLoading}
    disabled={false}
    onClick={onSubmit}
    >
      Log out
    </StyledLogoutButton>
  </FooterContainer>;
}

function UserSidebar() {
  return <StyledSidebar>
    <StyledMenu
      menuItemStyles={{
        button: {
          // the active class will be added automatically by react router
          // so we can use it to style the active menu item
          [`&.active`]: {
            backgroundColor: '#13395e',
            color: '#b6c8d9',
          },
        },
      }}
    >
      <CenteredTitle>SFIAF</CenteredTitle>
      <CenteredSubtitle>Artist</CenteredSubtitle>
      <StyledMenuItem component={<Link to="/show" />}>Shows</StyledMenuItem>
      <SidebarFooter/>
    </StyledMenu>
  </StyledSidebar>;
}

function SuperuserSidebar() {
  return <StyledSidebar>
    <StyledMenu
      menuItemStyles={{
        button: {
          // the active class will be added automatically by react router
          // so we can use it to style the active menu item
          [`&.active`]: {
            backgroundColor: '#13395e',
            color: '#b6c8d9',
          },
        },
      }}
    >
      <CenteredTitle>SFIAF</CenteredTitle>
      <CenteredSubtitle>Admin</CenteredSubtitle>
      <StyledMenuItem component={<Link to="/festival" />}> Festivals</StyledMenuItem>
      <StyledMenuItem component={<Link to="/discipline" />}> Disciplines</StyledMenuItem>
      <StyledMenuItem component={<Link to="/show" />}> Shows</StyledMenuItem>
      <StyledMenuItem component={<Link to="/location" />}> Locations</StyledMenuItem>
      <StyledMenuItem component={<Link to="/artist" />}> Artists</StyledMenuItem>
      <StyledMenuItem component={<Link to="/admin" />}> Admins</StyledMenuItem>
      <SidebarFooter/>
    </StyledMenu>
  </StyledSidebar>;
}



export interface AuthenticatedScreenProps {
  panel: AuthenticatedPanel | SuperuserAuthenticatedPanel;
}

export function AuthenticatedScreen({panel}: AuthenticatedScreenProps) {

  return <>
    <FestivalContextProvider>
      <CountryContextProvider>
        <AuthenticatedScreenInternal panel={panel}/>
      </CountryContextProvider>
    </FestivalContextProvider>
  </>;
}

interface AuthenticatedPanelWrapperProps extends AuthenticatedScreenProps {

}

function GetAuthenticatedPanel(panel: AuthenticatedPanel | SuperuserAuthenticatedPanel) {
  switch (panel) {
    case AuthenticatedPanel.ShowDetails:
      return ShowDetailsPanel;
    case AuthenticatedPanel.ShowList:
      return ShowListPanel;
    case SuperuserAuthenticatedPanel.ShowCreate:
      return ShowCreatePanel;
    case SuperuserAuthenticatedPanel.DisciplineCreate:
      return DisciplineCreatePanel;
    case SuperuserAuthenticatedPanel.DisciplineDetails:
      return DisciplineDetailsPanel;
    case SuperuserAuthenticatedPanel.DisciplineList:
      return DisciplineListPanel;
    case SuperuserAuthenticatedPanel.FestivalCreate:
      return FestivalCreatePanel;
    case SuperuserAuthenticatedPanel.FestivalDetails:
      return FestivalDetailsPanel;
    case SuperuserAuthenticatedPanel.LocationCreate:
      return LocationCreatePanel;
    case SuperuserAuthenticatedPanel.LocationDetails:
      return LocationDetailsPanel;
    case SuperuserAuthenticatedPanel.LocationList:
      return LocationListPanel;
    case SuperuserAuthenticatedPanel.SuperuserCreate:
      return SuperuserCreatePanel;
    case SuperuserAuthenticatedPanel.SuperuserDetails:
      return SuperuserDetailsPanel;
    case SuperuserAuthenticatedPanel.SuperuserList:
      return SuperuserListPanel;
    case SuperuserAuthenticatedPanel.UserCreate:
      return UserCreatePanel;
    case SuperuserAuthenticatedPanel.UserDetails:
      return UserDetailsPanel;
    case SuperuserAuthenticatedPanel.UserList:
      return UserListPanel;
    case SuperuserAuthenticatedPanel.FestivalList:
    default:
      return FestivalListPanel;
  }
}

function AuthenticatedPanelWrapper({panel}: AuthenticatedPanelWrapperProps) {
  const PanelComponent = GetAuthenticatedPanel(panel);

  return <AuthPanelContainer>
    <PanelComponent/>
</AuthPanelContainer>
}

export function AuthenticatedScreenInternal({panel}: AuthenticatedScreenProps) {
  const { authState, apiState } = useContextOrThrow(AuthContext);
  const { setCountries } = useContextOrThrow(CountryContext);
  const { setFestivals, setDisciplines, setDisciplinesByYear, setLocations, setLocationsByYear } = useContextOrThrow(FestivalContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // a little ugly: the apiState is not immediately set b/c it needs to be fetched from storage and then built manually.
  //  therefore, we need to monitor when `apiState` changes (and is set to be non-null), then we can do our requisite fetching.
  useAsyncEffect(async () => {
    if (apiState == null) {
      return;
    }

    try {
      const countryApi = apiState!.countryApi;
      const countries = await countryApi.getAllCountries();
      setCountries(countries);
    }
    catch(e) {
      toast(`Unexpected error. Please refresh and try again. (Error details: ${e})`);
    }

    let festivals: Array<Festival>;
    try {
      if (authState!.userType === UserType.Superuser) {
        const api: SuperuserFestivalApi = apiState!.festivalApi as SuperuserFestivalApi;
        festivals = await api.getAllFestivals();
        const sortedFestivals = festivals.sort((a, b) => a.year - b.year);
        setFestivals(sortedFestivals);
      }
      else {
        const api: UserFestivalApi = apiState!.festivalApi as UserFestivalApi;
        festivals = await api.getAllFestivalsForCurrentUser();
        const sortedFestivals = festivals.sort((a, b) => a.year - b.year);
        setFestivals(sortedFestivals);
      }
    }
    catch (e) {
      toast(`Unexpected error. Please refresh and try again. (Error details: ${e})`);
      return;
    }

    try {
      const api = apiState!.disciplineApi;
      const disciplinesArray: Array<Discipline> = await backOff(async () => await api.getAllDisciplines());

      const disciplinesMap: Map<number, Discipline> = new Map();
      for (const discipline of disciplinesArray) {
        disciplinesMap.set(discipline.id, discipline);
      }
      setDisciplines(disciplinesMap);

      const disciplinesByYearMap: Map<number, Array<Discipline>> = new Map();
      for (const festival of festivals!) {
        disciplinesByYearMap.set(festival.year, new Array());
      }

      for (const discipline of disciplinesArray) {
        const { festivalYear } = discipline;

        // should never occur
        if (disciplinesByYearMap.has(festivalYear) !== true) {
          disciplinesByYearMap.set(festivalYear, new Array());
        }

        const existingDisciplines = disciplinesByYearMap.get(festivalYear)!;
        existingDisciplines.push(discipline);

        // inefficient to do it per element, should extract out to dedicated sort
        const sortedDisciplines = existingDisciplines.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          else if (a.name < b.name) {
            return -1;
          }
          else {
            return 0;
          }
        });

        disciplinesByYearMap.set(festivalYear, sortedDisciplines);
      }

      setDisciplinesByYear(disciplinesByYearMap);
    }
    catch (e) {
      toast(`Unexpected error. Please refresh and try again. (Error details: ${e})`);
      return;
    }

    try {
      const locationApi = apiState!.locationApi;
      const locationsArray = await backOff(async () => await locationApi.getAllLocations());

      const locationsMap: Map<number, Location> = new Map();
      for (const location of locationsArray) {
        locationsMap.set(location.id, location);
      }
      setLocations(locationsMap);

      const locationsByYear: Map<number, Array<Location>> = new Map();
      for (const festival of festivals!) {
        locationsByYear.set(festival.year, new Array());
      }

      for (const location of locationsArray) {
        const { festivalYear } = location;

        // shouldn't happen, but just in case
        if (locationsByYear.has(festivalYear) !== true) {
          locationsByYear.set(festivalYear, new Array());
        }
        const existingLocations = locationsByYear.get(festivalYear)!;
        existingLocations.push(location);

        // inefficient to do it per element, should extract out to dedicated sort
        const sortedLocations = existingLocations.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          else if (a.name < b.name) {
            return -1;
          }
          else {
            return 0;
          }
        });

        locationsByYear.set(festivalYear, sortedLocations);
      }

      setLocationsByYear(locationsByYear);
    }
    catch(e) {
      toast(`Unexpected error. Please refresh and try again. (Error details: ${e})`);
    }

    setIsLoading(false);
  },
  undefined,
  [apiState]);

  return <MainContainer>
    <IfElse on={isLoading}>
      <>
        <FullHeightCenteredContentContainer>
        <TailSpin
          visible={true}
          height="50"
          width="50"
          color={Theme.color.orange}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </FullHeightCenteredContentContainer>
      </>
      <>
        <IfElse on={authState!.userType === UserType.Superuser}>
          <SuperuserSidebar/>
          <UserSidebar/>
        </IfElse>

        <AuthenticatedPanelWrapper panel={panel}/>
      </>
    </IfElse>
    
  </MainContainer>;
}