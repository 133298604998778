import { AuthenticatedHeader, AuthenticatedHeaderTitle, AuthenticatedSubheader } from "../components/AuthenticatedHeader";
import { ColoredLink } from "../components/ColoredLink";
import { BaseError, Conditional, CreateFestival, Festival, ValidateCreateFestival } from "@sfiaf/common";
import { useContextOrThrow } from "../utils/useContextOrThrow";
import { AuthContext } from "../contexts/AuthContext";
import { FestivalContext } from "../contexts/FestivalContext";
import { useState } from "react";
import { useValidatorResolver } from "../utils/useValidationResolver";
import { SubmitHandler, useForm } from "react-hook-form";
import { SuperuserFestivalApi } from "@sfiaf/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContainer } from "../components/FormContainer";
import { ValidatedInput } from "../components/ValidatedField";
import { SpinnerButton } from "../components/SpinnerButton";
import { ErrorLabel } from "../components/ErrorLabel";

function *CreateFestivalValidator(input: CreateFestival) {
  yield *ValidateCreateFestival(input);
}

export function FestivalCreatePanel() {
  const { apiState } = useContextOrThrow(AuthContext);
  const {
    festivals,
    setFestivals,
    disciplinesByYear,
    setDisciplinesByYear,
    locationsByYear,
    setLocationsByYear, } = useContextOrThrow(FestivalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<BaseError | null>(null);
  const navigate = useNavigate();

  const resolver = useValidatorResolver(CreateFestivalValidator);
  const { register, handleSubmit, formState: { errors } } = useForm<CreateFestival>({
    resolver,
  });

  const onSubmit: SubmitHandler<CreateFestival> = async (createFestival) => {
    setIsLoading(true);

    try {
      const api = apiState!.festivalApi as SuperuserFestivalApi;
      const resultFestival: Festival = await api.createFestival(createFestival);

      festivals.push(resultFestival);
      setFestivals(festivals);

      // by contract, the disciplinesByYear and locationsByYear map should *always* have a kv-pair
      //  for each festival year, so make sure these are updated along the way.
      disciplinesByYear.set(resultFestival.year, new Array());
      setDisciplinesByYear(disciplinesByYear);

      locationsByYear.set(resultFestival.year, new Array());
      setLocationsByYear(locationsByYear);

      setIsLoading(false);
      toast('Success');
      navigate('/festival');
    }
    catch (e) {
      const error: BaseError = (e as BaseError);
      setApiError(error);
      setIsLoading(false);
    }
  };
  
  return <>
    <AuthenticatedHeader>
      <AuthenticatedHeaderTitle>Create Festival</AuthenticatedHeaderTitle>
    </AuthenticatedHeader>
    <AuthenticatedSubheader>
      <ColoredLink to='/festival'>&lt; Festivals</ColoredLink>
    </AuthenticatedSubheader>
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <ValidatedInput type='number' fieldName='year' register={register} errors={errors}/>
      <ValidatedInput type='date' fieldName='startDate' register={register} errors={errors}/>
      <ValidatedInput type='date' fieldName='endDate' register={register} errors={errors}/>
      <SpinnerButton type='submit' isLoading={isLoading}>
        Create
      </SpinnerButton>
      <Conditional on={apiError}>
        <ErrorLabel>
          {apiError?.message}
        </ErrorLabel>
      </Conditional>
    </FormContainer>
  </>;
}